import React from "react";
import { ClipLoader } from "react-spinners";

export default function CustomButton({
  title,
  customStyle,
  handleClick,
  type,
  isLoading,
  buttonType,
}) {
  const color =
    type === "secondary"
      ? "bg-secondary-color"
      : type === "success"
      ? "bg-success-color text-white"
      : type === "success_outline"
      ? "text-success-color border-2 border-success-color hover:bg-success-color hover:text-white"
      : type === "danger"
      ? "bg-danger-color text-white"
      : type === "danger_outline"
      ? "text-danger-color border-2 border-danger-color hover:bg-danger-color hover:text-white"
      : type === "regular"
      ? "bg-text-2-color text-white hover:border-text-2-color"
      : type === "regular_outline"
      ? "text-text-2-color border-2 border-text-2-color hover:bg-text-2-color hover:text-white"
      : type === "outline"
      ? "bg-transparent outline outline-1 outline-text-1-color text-text-1-color hover:bg-secondary-color hover:text-white"
      : type === "blue" 
      ? "bg-blue text-white hover:border-blue"
      : type === "blueGrey" 
      ? "bg-blueGrey text-white hover:border-blueGrey"
      : type === "primary" 
      ? "bg-primary-color text-white"
      : "bg-secondary-color text-white";
  return (
    <button
      disabled={isLoading ? true : false}
      onClick={handleClick}
      type={buttonType}
      className={`${customStyle} ${color} w-full rounded-full`}
    >
      {isLoading ? <ClipLoader color="#ffffff" size={"25"} /> : title}
    </button>
  );
}
