import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestorsTotal = async (statusCondition) => {
    const investmentsCollection = collection(firestore, "investments");

    let q;
    if (statusCondition === "APPROVED") {
        q = query(investmentsCollection, where("status", "==", "APPROVED"));
    } else if (statusCondition === "NON_APPROVED") {
        q = query(investmentsCollection, where("status", "!=", "APPROVED"));
    } else {
        throw new Error("Invalid status condition");
    }

    const querySnapshot = await getDocs(q);
    
    const userIdSet = new Set();
    
    querySnapshot.forEach((doc) => {
        const investorObj = doc.data();
        userIdSet.add(investorObj.userId);
    });
    
    return userIdSet.size;
};

export default getInvestorsTotal;
