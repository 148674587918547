import React from "react";

export default function CustomLabel({ title, status }) {
  return status === "SUCCESS" ? (
    <div className="px-6 py-2 rounded-full bg-success-color bg-opacity-20">
      <p className="text-success-color">{title}</p>
    </div>
  ) : status === "REGULAR" ? (
    <div className="px-6 py-2 rounded-full bg-ice2-color">
      <p className="text-text-2-color">{title}</p>
    </div>
  ) : status === "DANGER" ? (
    <div className="px-6 py-2 rounded-full bg-danger-color bg-opacity-20">
      <p className="text-danger-color">{title}</p>
    </div>
  ) : status === "WARNING" ? (
    <div className="px-6 py-2 rounded-full bg-primary-color bg-opacity-20">
      <p className="text-primary-color">{title}</p>
    </div>
  ) : status === "BLUE" 
  ? (
    <div className="px-6 py-2 rounded-full bg-blue bg-opacity-20">
      <p className="text-blue">{title}</p>
    </div>
  ) : status === "PURPLE" ? (
    <div className="px-6 py-2 rounded-full bg-purple bg-opacity-20">
      <p className="text-purple">{title}</p>
    </div>
  ) : (
    ""
  );
}
