import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getAnnouncements = async () => {
    const announvementsCollection = collection(firestore, "announcements");
    const q = query(announvementsCollection, where("type", "==", "VOTING"));
    const querySnapshot = await getDocs(q);

    const announcementsData = querySnapshot.docs.map((data) => {
        var annObj = data.data();
        annObj['id'] = data.id;
        return annObj
    });
    
    return announcementsData;
}
export default getAnnouncements;