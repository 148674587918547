import { collection, getCountFromServer, query } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getPreActiveLogsCount = async() => {
    const preActiveLogsCollection = collection(firestore, "preActiveLogs");
    const q = query(preActiveLogsCollection)
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
}

export default getPreActiveLogsCount;