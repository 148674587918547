import React from "react";
import receiptLogo from "../assets/images/RECEIPT_LOGO.png";
import receiptSignature from "../assets/images/RECEIPT_SIGNATURE.png";
import receiptFooter from "../assets/images/RECEIPT_FOOTER.png";
import { formatDate } from "../utils/formatDate";

export default function Receipt({ name, amountReceived, date }) {
  return (
    <div className="border border-black p-5 bg-white">
      <div className="flex justify-end items-center relative mb-60">
        <div className="absolute top-10">
          <div className="bg-black text-white px-16 pb-6 flex items-center justify-center text-2xl">
            <p>سند قبض</p>
          </div>
        </div>
        <div className="absolute top-20 inset-0 flex justify-center items-center">
          <img alt="qaswarah" src={receiptLogo} className="h-32" />
        </div>
      </div>

      <div className="px-60 text-3xl">
        <div className="mb-20 leading-relaxed">
            <p>استلمنا من السيد/ة {name}</p>
            <p>مبلغ وقدره {amountReceived.toLocaleString()} ريال سعودي فقط لاغير.</p>
            <p>وذلك مقابل حصة استثمار في أحد مشاريع الشركة كما هو مذكور في العقد.</p>
        </div>

        <div className="mb-20">
            <p>في تاريخ {formatDate(date)}</p>
        </div>

        <div className="flex justify-end mb-5">
            <p>توقيع المحاسب المستلم</p>
        </div>

        <div className="flex justify-end items-center relative mb-20">
        <img alt="qaswarah" src={receiptSignature} className="h-20" />
        <div className="absolute inset-0 flex justify-center items-center">
          <p>والله الموفق</p>
        </div>
      </div>
      
      <div className="flex justify-center">
        <img alt="qaswarah" src={receiptFooter} className="h-20" />
      </div>

      </div>
    </div>
  );
}
