import { collection, getCountFromServer, query } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestmentsPromoUsageCount = async() => {
    const investmentsPromoUsageCollection = collection(firestore, "investmentPromosUsage");
    const q = query(investmentsPromoUsageCollection)
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
}

export default getInvestmentsPromoUsageCount;