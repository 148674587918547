import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getNotificationTypeCount = async(type) => {
    const notificationsCollection = collection(firestore, "notifications");
    const q = query(notificationsCollection, where("data.type", "==", type))
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
}

export default getNotificationTypeCount;