import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getNotificationsByType = async (type) => {
  const notificationsCollection = collection(firestore, "notifications");
  const q = query(
    notificationsCollection,
    orderBy("createdAt", "desc"),
    where("data.type", "==", type)
  );
  const querySnapshot = await getDocs(q);

  const notificationsData = querySnapshot.docs
    .map((data) => {
      var notiObj = data.data();
      notiObj["id"] = data.id;
      return notiObj;
    })
    .filter(Boolean);

  return notificationsData;
};
export default getNotificationsByType;
