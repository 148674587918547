import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import StatNumberItem from "./components/StatNumberItem";
import getUsers from "../../lib/users/getUsers";
import PageLoader from "../../components/PageLoader";
import getInvestorsTotal from "../../lib/investments/getInvestorsTotal";
import getInvestmentsCount from "../../lib/investments/getInvestmentsCount";
import getCampaigns from "../../lib/campaigns/getCampaigns";
import getCampaignsInvestmentsAmount from "../../lib/campaigns/getCampaignsInvestmentsAmount";
import { formatAvgTime } from "../../utils/formatAvgTime";
import getCampaignsCount from "../../lib/campaigns/getCampaignsCount";
import getRegistrationPromoUsageCount from "../../lib/registration_promos/getRegistrationPromoUsageCount";
import getInvestmentsPromoUsageCount from "../../lib/investment_promos/getInvestmentsPromoUsageCount";
import getPreActiveLogsCount from "../../lib/pre_active_logs/getPreActiveLogsCount";
import CustomLabel from "../../components/CustomLabel";
import BarChart from "../../components/charts/BarChart";
import { NATIONALITIES } from "../../helpers/nationalities";
import { REGIONS } from "../../helpers/regions";
import CampaignStats from "./components/CampaignStats";
import StatsItem from "./components/StatsItem";
import DoughnutChart from "../../components/charts/DoughnutChart";
import getInvestments from "../../lib/investments/getInvestments";
import getCampaignById from "../../lib/campaigns/getCampaignById";
import getUserById from "../../lib/users/getUserById";
import getInvestmentPromosUsagesByUser from "../../lib/investment_promos/getInvestmentPromosUsagesByUser";
import getRegistrationPromosUsagesByUser from "../../lib/registration_promos/getRegistrationPromosUsagesByUser";
import getInvestmentPromosUsagesByCampaign from "../../lib/investment_promos/getInvestmentPromosUsagesByCampaign";
import getNotificationTypeCount from "../../lib/notifications/getNotificationTypeCount";

export default function StatisticsPage() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState("");
  const [totalInvestors, setTotalInvestors] = useState("");
  const [totalNonInvestors, setTotalNonInvestors] = useState("");
  const [investmentsCount, setInvestmentsCount] = useState({});
  const [overallAvgTime, setOverallAvgTime] = useState(null);
  const [walletTotal, setWalletTotal] = useState("");
  const [totalCampaigns, setTotalCampaigns] = useState("");
  const [totalActiveCampaigns, setTotalActiveCampaigns] = useState("");
  const [totalPreActiveCampaigns, setTotalPreActiveCampaigns] = useState("");
  const [totalDeletedCampaigns, setTotalDeletedCampaigns] = useState("");
  const [totalCompletedCampaigns, setTotalCompletedCampagins] = useState("");
  const [totalRegistrationPromoUsage, setTotalRegistrationPromoUsage] = useState("");
  const [totalInvestmentPromoUsage, setTotalInvestmentPromoUsage] = useState("");
  const [totalPreActiveLogs, setTotalPreActiveLogs] = useState("");
  const [nationalitiesCount, setNationalitiesCount] = useState([]);
  const [citiesCount, setCitiesCount] = useState([]);
  const [campaignStatsData, setCampaignStatsData] = useState({});
  const [campaignInvestmentsData, setCampaignInvestmentsData] = useState({})
  const [investorsCitiesCount, setInvestorsCitiesCount] = useState([]);
  const [investorsNationalitiesCount, setInvestorsNationalitiesCount] = useState([]);
  const [top3InvestmentPromosUsages, setTop3InvestmentPromosUsages] = useState([]);
  const [top3RegistrationPromosUsages, setTop3RegistrationPromosUsages] = useState([]);
  const [investmentPromosByCampaign, setInvestmentPromosByCampaign] = useState({});
  const [notificationsCount, setNotificationsCount] = useState({});
  const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);

  const fetchInsights = async () => {
    // Getting data
    const users = await getUsers();
    const usersCount = users.length;
    const totalInvestors = await getInvestorsTotal("APPROVED");
    const totalNonInvestors = (users.length - totalInvestors);
    const totalApprovedInvestments = await getInvestmentsCount("APPROVED");
    const totalRejectedInvestments = await getInvestmentsCount("REJECTED");
    const totalPendingInvestments = await getInvestmentsCount("PENDING");
    const totalDeletedInvestments = await getInvestmentsCount("DELETED");
    const campaignsData = await getCampaigns();
    const investments = await getInvestments();
    const walletAmounts = await getCampaignsInvestmentsAmount();
    const totalCampaigns = campaignsData.length;
    const totalActiveCampaigns = await getCampaignsCount("ACTIVE");
    const totalPreActiveCampaigns = await getCampaignsCount("PREACTIVE");
    const totalDeletedCampaigns = await getCampaignsCount("DELETED");
    const totalCompletedCampaigns = await getCampaignsCount("COMPLETED");
    const totalRegistrationPromoUsage = await getRegistrationPromoUsageCount();
    const totalInvestmentPromoUsage = await getInvestmentsPromoUsageCount();
    const totalPreActiveLogs = await getPreActiveLogsCount();
    const notificationsCount = {
      newCampaings: await getNotificationTypeCount("NEW_CAMPAIGN"),
      preActiveCampaigns: await getNotificationTypeCount("PREACTIVE_CAMPAIGN"),
      activeCampaigns: await getNotificationTypeCount("ACTIVE_CAMPAIGN"),
      completeCampaigns: await getNotificationTypeCount("COMPLETE_CAMPAIGN"),
      acceptPreActive: await getNotificationTypeCount("ACCEPT_PREACTIVE"),
      rejectPreActive: await getNotificationTypeCount("REJECT_PREACTIVE"),
      acceptInvestment: await getNotificationTypeCount("ACCEPT_INVESTMENT"),
      rejectInvestment: await getNotificationTypeCount("REJECT_INVESTMENT"),
      acceptFirstInvestment: await getNotificationTypeCount("ACCEPT_FIRST_INVESTMENT"),
      investorReport: await getNotificationTypeCount("INVESTOR_REPORT"),
      investorGeneral: await getNotificationTypeCount("INVESTOR_GENERAL_NOTIFCATION"),
    };
    const totalNotificationsCount = Object.values(notificationsCount).reduce((sum, count) => sum + count, 0);
    setTotalNotificationsCount(totalNotificationsCount);
    // Calculate campaign data. --------------------------
    const tempArr = [];
    // Max investment amount
    const tempInvestmentsMaxAmount = [];
    let investmentAmountData = {
      maxAmount: -Infinity,
      minAmount: Infinity,
      avgAmount: 0,
      maxTitle: "",
      minTitle: "",
    };
    // Share number
    const tempShareNumbers = [];
    let shareNumberData = {
      maxAmount: -Infinity,
      minAmount: Infinity,
      avgAmount: 0,
      maxTitle: "",
      minTitle: "",
      avgInvestorShareNumber: 0,
    }
    // Units number
    const tempUnitsNumber = [];
    let unitsNumberData = {
      maxAmount: -Infinity,
      minAmount: Infinity,
      avgAmount: 0,
      maxTitle: "",
      minTitle: "",
    };

    campaignsData.forEach(async(data) => {
      // investment max amount
      if (data.investmentMaxAmount !== undefined) {
        tempInvestmentsMaxAmount.push(data.investmentMaxAmount);

        // Max amount
        if (data.investmentMaxAmount > investmentAmountData.maxAmount) {
          investmentAmountData.maxAmount = data.investmentMaxAmount;
          investmentAmountData.maxTitle = data.title;
        }

        // Min amount
        if (data.investmentMaxAmount < investmentAmountData.minAmount) {
          investmentAmountData.minAmount = data.investmentMaxAmount;
          investmentAmountData.minTitle = data.title;
        }
      }

      // Share number
      if(data.shareNumber !== undefined) {
        tempShareNumbers.push(data.shareNumber);


        // Max share number
        if(data.shareNumber > shareNumberData.maxAmount){
          shareNumberData.maxAmount = data.shareNumber;
          shareNumberData.maxTitle = data.title;
        }

        // Min share number
        if(data.shareNumber < shareNumberData.minAmount){
          shareNumberData.minAmount = data.shareNumber;
          shareNumberData.minTitle = data.title;
        }
      }

      // Unit numbers
      if(data.unitsNumber !== undefined) {
        tempUnitsNumber.push(data.unitsNumber);


        // Max units number
        if(data.unitsNumber > unitsNumberData.maxAmount){
          unitsNumberData.maxAmount = data.unitsNumber;
          unitsNumberData.maxTitle = data.title;
        }

        // Min units number
        if(data.unitsNumber < unitsNumberData.minAmount){
          unitsNumberData.minAmount = data.unitsNumber;
          unitsNumberData.minTitle = data.title;
        }
      }

      // avg time
      if (data.createdAt && data.closedAt) {
        const createdAt = data.createdAt.toDate();
        const closedAt = data.closedAt.toDate();
        const timeDifference = closedAt.getTime() - createdAt.getTime();
        tempArr.push(timeDifference);
      }
    });
    
    // avg investment amount
    if(tempInvestmentsMaxAmount.length > 0){
      const avgInvestment = tempInvestmentsMaxAmount.reduce((sum, amount) => sum + amount, 0) / tempInvestmentsMaxAmount.length;
      investmentAmountData.avgAmount = avgInvestment;
    }
    // avg share number
    if(tempShareNumbers.length > 0){
      const avgShareNumber = tempShareNumbers.reduce((sum, amount) => sum + amount, 0) / tempShareNumbers.length;
      const investorsShareNumber = tempShareNumbers.reduce((sum, amount) => sum + amount, 0) / totalInvestors;
      shareNumberData.avgAmount = avgShareNumber;
      shareNumberData.avgInvestorShareNumber = investorsShareNumber;
    }
    // avg units number
    if(tempUnitsNumber.length > 0){
      const avgUnitsNumber = tempUnitsNumber.reduce((sum, amount) => sum + amount, 0) / tempUnitsNumber.length;
      unitsNumberData.avgAmount = avgUnitsNumber;
    }

    // total avg time
    const totalAvgTime = tempArr.reduce((acc, cur) => acc + cur, 0) / tempArr.length;

    let campaignStats = {investmentAmountData, shareNumberData, unitsNumberData}

    // investments campaigns count
    const campaignCounts = investments.reduce((counts, investment) => {
      const { campaignId } = investment;
      counts[campaignId] = (counts[campaignId] || 0) + 1;
      return counts;
    }, {});

    // Find the most repeated campaign title and count
    const mostRepeated = Object.entries(campaignCounts).reduce(async (maxPromise, [campaignId, count]) => {
      const max = await maxPromise;
      const campaign = await getCampaignById(campaignId);
      if (count > max.count) {
        return { campaignTitle: campaign.title, count };
      } else {
        return max;
      }
    }, Promise.resolve({ title: null, count: -Infinity }));

    // Find the least repeated campaign title and count
    const leastRepeated = Object.entries(campaignCounts).reduce(async (minPromise, [campaignId, count]) => {
      const min = await minPromise;
      const campaign = await getCampaignById(campaignId);
      if (count < min.count) {
        return { campaignTitle: campaign.title, count };
      } else {
        return min;
      }
    }, Promise.resolve({ title: null, count: Infinity }));

    // Usage
    const mostRepeatedResult = await mostRepeated;
    const leastRepeatedResult = await leastRepeated;
  
    // Calculate the average occurrence of campaignIds
    const totalCount = Object.values(campaignCounts).reduce((total, count) => total + count, 0);
    const averageOccurrence = totalCount / Object.keys(campaignCounts).length;

    const campaignInvestments = {
      max: mostRepeatedResult,
      min: leastRepeatedResult,
      avgAmount: averageOccurrence,
    }

    let investorsNationalities = {};
    let investorsCities = {};
    
    // Loop through investments and fetch user information
    await Promise.all(investments.map(async (data) => {
        const user = await getUserById(data.userId);
        if (user && user.nationality && user.city) {
            // Count nationalities
            if (investorsNationalities[user.nationality]) {
                investorsNationalities[user.nationality]++;
            } else {
                investorsNationalities[user.nationality] = 1;
            }
            // Count cities
            if (investorsCities[user.city]) {
                investorsCities[user.city]++;
            } else {
                investorsCities[user.city] = 1;
            }
        }
    }));
    
    // Extract nationalities and counts into separate arrays
    const nationalities = Object.keys(investorsNationalities);
    const nationalityCountsArray = Object.values(investorsNationalities);
    
    // Extract cities and counts into separate arrays
    const cities = Object.keys(investorsCities);
    const cityCountsArray = Object.values(investorsCities);
    
    // Sort nationalities and cities based on counts
    nationalities.sort((a, b) => investorsNationalities[b] - investorsNationalities[a]);
    cities.sort((a, b) => investorsCities[b] - investorsCities[a]);
    
    // Get top 8 nationalities and cities
    const top8Nationalities = nationalities.slice(0, 8).map(nationality => NATIONALITIES[nationality]);
    const top8NationalityCounts = nationalityCountsArray.slice(0, 8);
    const top8Cities = cities.slice(0, 8).map(city => REGIONS['SA'][city]);
    const top8CityCounts = cityCountsArray.slice(0, 8);

    setInvestorsCitiesCount([top8Cities, top8CityCounts]);
    setInvestorsNationalitiesCount([top8Nationalities, top8NationalityCounts])

    // -------------------------------------------------------------------------------------------------

    // Users demographic analysis

    // Get top 8 nationalities --------------------------

    const nationalityCounts = users.reduce((acc, user) => {
      acc[user.nationality] = (acc[user.nationality] || 0) + 1;
      return acc;
    }, {});

    const sortedNationalities = Object.entries(nationalityCounts)
      .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
      .slice(0, 8); // Get the top 8

    // Extract labels and values from sortedNationalities
    const nLabels = sortedNationalities.map(
      ([nationality]) => NATIONALITIES[nationality]
    );
    const nValues = sortedNationalities.map(([, count]) => count);

    setNationalitiesCount([nLabels, nValues]);

    // Get top 8 cities --------------------------

    const cityCounts = users.reduce((acc, user) => {
      acc[user.city] = (acc[user.city] || 0) + 1;
      return acc;
    }, {});

    const sortedCities = Object.entries(cityCounts)
      .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
      .slice(0, 8); // Get the top 8

    // Extract labels and values from sortedNationalities
    const cLabels = sortedCities.map(([city]) => REGIONS["SA"][city]);
    const cValues = sortedCities.map(([, count]) => count);

    setCitiesCount([cLabels, cValues]);

    // -------------------------------------------------------------------------------------------------

    // investment & registration promos ----------------------------------------------------

    const investmentPromosUsagesCount = [];
    const registrationPromosUsagesCount = [];

    const usersPromises = users.map(async (user) => {
      try {
        const [investmentUsageCount, registrationUsageCount] = await Promise.all([
          getInvestmentPromosUsagesByUser(user.id),
          getRegistrationPromosUsagesByUser(user.id),
        ]);
  
        if (investmentUsageCount > 0) {
          const existingInvestmentUser = investmentPromosUsagesCount.find(u => u.userId === user.id);
          if (existingInvestmentUser) {
            existingInvestmentUser.usageCount += investmentUsageCount;
          } else {
            investmentPromosUsagesCount.push({ userId: user.id, usageCount: investmentUsageCount, name: user.name });
          }
        }
  
        if (registrationUsageCount > 0) {
          const existingRegistrationUser = registrationPromosUsagesCount.find(u => u.userId === user.id);
          if (existingRegistrationUser) {
            existingRegistrationUser.usageCount += registrationUsageCount;
          } else {
            registrationPromosUsagesCount.push({ userId: user.id, usageCount: registrationUsageCount, name: user.name });
          }
        }
  
      } catch (error) {
        console.error(`Error fetching usage counts for user ${user.id}:`, error);
      }
    });

    let investmentPromoUsageByCampaign = {
      maxAmount: -Infinity,
      minAmount: Infinity,
      avgAmount: 0,
      maxTitle: "",
      minTitle: "",
    };

    let tempUsageArr = [];

    const campaignPromises = campaignsData.map(async (campaign) => {
      try {
        const campaignsUsageCount = await getInvestmentPromosUsagesByCampaign(campaign.id);
        if(campaignsUsageCount > 0){
          if(campaignsUsageCount > investmentPromoUsageByCampaign.maxAmount){
            investmentPromoUsageByCampaign.maxAmount = campaignsUsageCount;
            investmentPromoUsageByCampaign.maxTitle = campaign.title;
          }

          if(campaignsUsageCount < investmentPromoUsageByCampaign.minAmount){
            investmentPromoUsageByCampaign.minAmount = campaignsUsageCount;
            investmentPromoUsageByCampaign.minTitle = campaign.title;
          }

          tempUsageArr.push(campaignsUsageCount);
          
        }
      } catch (error) {
        console.error(`Error fetching usage counts for camp ${campaign.id}:`, error);
      }
    });

    
    // Wait for all the data to be fetched
    await Promise.all(usersPromises);
    await Promise.all(campaignPromises);


    const sum = tempUsageArr.reduce((acc, num) => acc + num, 0);
    investmentPromoUsageByCampaign.avgAmount = (sum / tempUsageArr.length);
  
    // Sort and get the top 3 users for investment promos
    investmentPromosUsagesCount.sort((a, b) => b.usageCount - a.usageCount);
    const top3InvestmentPromos = investmentPromosUsagesCount.slice(0, 3);
  
    // Sort and get the top 3 users for registration promos
    registrationPromosUsagesCount.sort((a, b) => b.usageCount - a.usageCount);
    const top3RegistrationPromos = registrationPromosUsagesCount.slice(0, 3);

    // Set the top 3 variables
    setTop3InvestmentPromosUsages(top3InvestmentPromos);
    setTop3RegistrationPromosUsages(top3RegistrationPromos);
    setInvestmentPromosByCampaign(investmentPromoUsageByCampaign);


    // -------------------------------------------------------------------------------------------------

    // Assigning values
    setTotalUsers(usersCount);
    setTotalInvestors(totalInvestors);
    setTotalNonInvestors(totalNonInvestors);
    setInvestmentsCount({
      approved: totalApprovedInvestments,
      rejected: totalRejectedInvestments,
      pending: totalPendingInvestments,
      deleted: totalDeletedInvestments,
      all: totalApprovedInvestments + totalRejectedInvestments + totalPendingInvestments + totalDeletedInvestments
    });
    setOverallAvgTime(totalAvgTime);
    setWalletTotal(walletAmounts);
    setTotalCampaigns(totalCampaigns);
    setTotalActiveCampaigns(totalActiveCampaigns);
    setTotalPreActiveCampaigns(totalPreActiveCampaigns);
    setTotalDeletedCampaigns(totalDeletedCampaigns);
    setTotalCompletedCampagins(totalCompletedCampaigns);
    setTotalRegistrationPromoUsage(totalRegistrationPromoUsage);
    setTotalInvestmentPromoUsage(totalInvestmentPromoUsage);
    setTotalPreActiveLogs(totalPreActiveLogs);
    setCampaignStatsData(campaignStats);
    setCampaignInvestmentsData(campaignInvestments);
    setNotificationsCount(notificationsCount);
    setIsPageLoading(false);
  };

  useEffect(() => {
    fetchInsights();
  }, []);
  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <Navbar />
      <div>
        <div className="flex flex-row justify-start items-center mb-10">
          <PageTitle title={"أهم الأرقام"} isSubtitle={true} />
        </div>
        <div className="mb-20 grid gap-10 desktop:grid-cols-4 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1">
          <StatNumberItem title="عدد المستخدمين" value={totalUsers} />
          <StatNumberItem title="عدد المستثمرين" value={totalInvestors} />
          <StatNumberItem
            title="عدد المستخدمين لم يستثمروا"
            value={totalNonInvestors}
          />
          <StatNumberItem
            title="عدد الإستثمارات المقبولة"
            value={investmentsCount.approved}
          />
          <StatNumberItem
            title="متوسط وقت إغلاق الفرص"
            value={formatAvgTime(overallAvgTime)}
          />
          <StatNumberItem
            title="قيمة المحفظة العقارية"
            value={`${walletTotal.toLocaleString()} ريال`}
          />
          <StatNumberItem title="إجمالي عدد العقارات" value={totalCampaigns} />
          <StatNumberItem
            title="عدد الفرص النشطة"
            value={totalActiveCampaigns}
          />
          <StatNumberItem
            title="عدد الفرص المتاحة للحجز"
            value={totalPreActiveCampaigns}
          />
          <StatNumberItem
            title="استخدام أكواد التسجيل"
            value={totalRegistrationPromoUsage}
          />
          <StatNumberItem
            title="استخدام الأكواد الإستثمارية"
            value={totalInvestmentPromoUsage}
          />
          <StatNumberItem
            title="إجمالي عدد الحجوزات"
            value={`${totalPreActiveLogs.toLocaleString()}`}
          />
        </div>

        <div className="flex flex-row justify-start items-center mb-10">
          <PageTitle
            title={"التحليل الديموغرافي للمستخدمين"}
            isSubtitle={true}
          />
        </div>
        <div className="grid mobile:grid-cols-1 laptop:grid-cols-2 gap-5 w-full ">
          <div className="mb-10 rounded-xl bg-ice-color p-5">
            <div className="flex">
              <CustomLabel title="الجنسيات" status="WARNING" />
            </div>
            <BarChart
              labels={nationalitiesCount[0]}
              values={Object.values(nationalitiesCount[1])}
            />
          </div>

          <div className="mb-10 rounded-xl bg-ice-color p-5">
            <div className="flex">
              <CustomLabel title="المدن" status="WARNING" />
            </div>
            <BarChart
              labels={citiesCount[0]}
              values={Object.values(citiesCount[1])}
            />
          </div>
        </div>

        <div className="flex flex-row justify-start items-center mb-10">
          <PageTitle title={"تحليل الفرص الإستثمارية"} isSubtitle={true} />
        </div>
        <div className="grid desktop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-5 mb-16">
          <CampaignStats value={totalDeletedCampaigns} status="DELETED" />
          <CampaignStats value={totalPreActiveCampaigns} status="PREACTIVE" />
          <CampaignStats value={totalActiveCampaigns} status="ACTIVE" />
          <CampaignStats value={totalCompletedCampaigns} status="COMPLETED" />
        </div>

        <div className="flex mb-10">
          <CustomLabel title="المبلغ المطلوب للإستثمار" status="WARNING" />
        </div>
        <div className="grid mobile:grid-cols-1 laptop:grid-cols-3 gap-10 mb-16">
          <StatsItem
            title1="أكبر استثمار مطلوب"
            title2={campaignStatsData.investmentAmountData.maxTitle}
            title3={`${campaignStatsData.investmentAmountData.maxAmount.toLocaleString()} ريال`}
          />
          <StatsItem
            title1="متوسط الإستثمار المطلوب"
            title2="لكل الفرص"
            title3={`${campaignStatsData.investmentAmountData.avgAmount.toLocaleString()} ريال`}
          />
          <StatsItem
            title1="أقل استثمار مطلوب"
            title2={campaignStatsData.investmentAmountData.minTitle}
            title3={`${campaignStatsData.investmentAmountData.minAmount.toLocaleString()} ريال`}
          />
        </div>

        <div className="flex mb-10">
          <CustomLabel title="عدد الحصص" status="WARNING" />
        </div>
        <div className="grid mobile:grid-cols-1 laptop:grid-cols-3 gap-10 mb-10">
          <StatsItem
            title1="أعلى حصص"
            title2={campaignStatsData.shareNumberData.maxTitle}
            title3={`${campaignStatsData.shareNumberData.maxAmount.toFixed(2)} حصة`}
          />
          <StatsItem
            title1="متوسط الحصص"
            title2="لكل الفرص"
            title3={`${campaignStatsData.shareNumberData.avgAmount.toFixed(2)} حصة`}
          />
          <StatsItem
            title1="أقل حصص"
            title2={campaignStatsData.shareNumberData.minTitle}
            title3={`${campaignStatsData.shareNumberData.minAmount.toFixed(2)} حصة`}
          />
        </div>
        <div className="mb-16">
        <StatsItem
            title1="متوسط عدد الحصص للمستثمرين (جميع عدد الحصص في الإستثمارات / مجموعة المستثمرين = X حصة)"
            title2=''
            title3={`${campaignStatsData.shareNumberData.avgInvestorShareNumber.toFixed(2)} حصة`}
          />
        </div>
        <div className="flex mb-10">
          <CustomLabel title="عدد الوحدات" status="WARNING" />
        </div>
        <div className="grid mobile:grid-cols-1 laptop:grid-cols-3 gap-10 mb-10">
          <StatsItem
            title1="أعلى وحدات"
            title2={campaignStatsData.unitsNumberData.maxTitle}
            title3={`${campaignStatsData.unitsNumberData.maxAmount.toFixed(2)} وحدة`}
          />
          <StatsItem
            title1="متوسط الوحدات"
            title2="لكل الفرص"
            title3={`${campaignStatsData.unitsNumberData.avgAmount.toFixed(2)} وحدة`}
          />
          <StatsItem
            title1="أقل وحدات"
            title2={campaignStatsData.unitsNumberData.minTitle}
            title3={`${campaignStatsData.unitsNumberData.minAmount.toFixed(2)} وحدة`}
          />
        </div>
        <div className="flex flex-row justify-start items-center mb-5">
          <PageTitle title={"التحاليل الإستثمارية"} isSubtitle={true} />
        </div>
        <div className="py-5 px-10 rounded-full bg-primary-color bg-opacity-20 flex mobile:flex-col tablet:flex-row gap-10 justify-between mb-10">
          <p className="text-primary-color">التوزيع الإستثماري بناء على حالة الإستثمار</p>
          <p className="text-primary-color">الإجمالي {investmentsCount.all.toLocaleString()} استثمار</p>
        </div>
        <div className="grid mobile:grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-4 gap-10 mb-10">
          <div className="flex flex-col items-center" style={{color: '#f82500'}}>
          <DoughnutChart
              labels={['المحذوفة', 'الإجمالي']}
              values={[investmentsCount.deleted, investmentsCount.all]}
              colors={['#f82500', '#e8eaec']}
              textColor='#f82500'
            />
            <p className="text-xl my-3">{investmentsCount.deleted}</p>
            <p className="text-xl">استثمارات محذوفة</p>
          </div>
          <div className="flex flex-col items-center" style={{color: '#cf4c6f'}}>
          <DoughnutChart
              labels={['المرفوضة', 'الإجمالي']}
              values={[investmentsCount.rejected, investmentsCount.all]}
              colors={['#cf4c6f', '#e8eaec']}
              textColor='#cf4c6f'
            />
            <p className="text-xl my-3">{investmentsCount.rejected}</p>
            <p className="text-xl">استثمارات مرفوضة</p>
          </div>
          <div className="flex flex-col items-center" style={{color: '#787878'}}>
          <DoughnutChart
              labels={['بانتظار القبول', 'الإجمالي']}
              values={[investmentsCount.pending, investmentsCount.all]}
              colors={['#787878', '#e8eaec']}
              textColor='#787878'
            />
            <p className="text-xl my-3">{investmentsCount.pending}</p>
            <p className="text-xl">بانتظار القبول</p>
          </div>
          <div className="flex flex-col items-center" style={{color: '#30c3af'}}>
          <DoughnutChart
              labels={['المقبولة', 'الإجمالي']}
              values={[investmentsCount.approved, investmentsCount.all]}
              colors={['#30c3af', '#e8eaec']}
              textColor='#30c3af'
            />
            <p className="text-xl my-3">{investmentsCount.approved}</p>
            <p className="text-xl">استثمارات مقبولة</p>
          </div>
        </div>
        <div className="flex mb-10">
            <CustomLabel title="توزيع طلبات الإستثمار للفرص الإستثمارية" status="WARNING" />
        </div>
        <div className="grid mobile:grid-cols-1 laptop:grid-cols-3 gap-10 mb-10">
          <StatsItem
            title1="أكثر عدد لفرصة"
            title2={campaignInvestmentsData.max.campaignTitle}
            title3={`${campaignInvestmentsData.max.count} استثمار`}
          />
          <StatsItem
            title1="متوسط عدد الإستثمارات"
            title2="لكل الفرص"
            title3={`${campaignInvestmentsData.avgAmount.toFixed(2)} استثمار`}
          />
          <StatsItem
            title1="أقل عدد لفرصة"
            title2={campaignInvestmentsData.min.campaignTitle}
            title3={`${campaignInvestmentsData.min.count} استثمار`}
          />
        </div>
        
        <div className="grid mobile:grid-cols-1 laptop:grid-cols-2 gap-5 w-full ">
          <div className="mb-10 rounded-xl bg-ice-color p-5">
            <div className="flex mb-10">
              <CustomLabel title="توزيع طلبات الإستثمار لمدن المستثمرين" status="WARNING" />
            </div>
            <BarChart
              labels={investorsCitiesCount[0]}
              values={Object.values(investorsCitiesCount[1])}
            />
          </div>
          <div className="mb-10 rounded-xl bg-ice-color p-5">
            <div className="flex mb-10">
              <CustomLabel title="توزيع طلبات الإستثمار لجنسية المستثمرين" status="WARNING" />
            </div>
            <BarChart
              labels={investorsNationalitiesCount[0]}
              values={Object.values(investorsNationalitiesCount[1])}
            />
          </div>
        </div>

        <div className="flex flex-row justify-start items-center mb-5">
          <PageTitle title={"تحاليل الأكواد الترويجية"} isSubtitle={true} />
        </div>
        <div className="flex mb-10">
            <CustomLabel title="التوزيع للأكواد الترويجية الإستثمارية على مزودينها من حيث الاستهلاك" status="WARNING" />
        </div>

        <div className="grid mobile:grid-cols-1 tablet:grid-cols-3 gap-5 w-full items-center mb-10">
          {top3InvestmentPromosUsages.length > 2 && (
            <div className="text-text-1-color flex flex-col items-center gap-5">
              <div className="rounded-full border-2 border-text-1-color w-20 h-20 flex justify-center text-2xl">
                <p className="m-auto">#3</p>
              </div>
              <p>{top3InvestmentPromosUsages[2].name}</p>
              <p>{`${top3InvestmentPromosUsages[2].usageCount.toLocaleString()} استخدام`}</p>
            </div>
          )}
          {top3InvestmentPromosUsages.length > 1 && (
            <div className="text-text-1-color flex flex-col items-center gap-5">
              <div className="rounded-full border-2 border-text-1-color w-32 h-32 flex justify-center text-2xl">
                <p className="m-auto">#2</p>
              </div>
              <p>{top3InvestmentPromosUsages[1].name}</p>
              <p>{`${top3InvestmentPromosUsages[1].usageCount.toLocaleString()} استخدام`}</p>
            </div>
          )}
          {top3InvestmentPromosUsages.length > 0 && (
            <div className="text-text-1-color flex flex-col items-center gap-5">
              <div className="rounded-full border-2 border-text-1-color w-44 h-44 flex justify-center text-2xl">
                <p className="m-auto">#1</p>
              </div>
              <p>{top3InvestmentPromosUsages[0].name}</p>
              <p>{`${top3InvestmentPromosUsages[0].usageCount.toLocaleString()} استخدام`}</p>
            </div>
          )}
        </div>

        <div className="flex mb-10">
            <CustomLabel title="التوزيع للأكواد الترويجية للتسجيل على مزودينها من حيث الاستهلاك" status="WARNING" />
        </div>

        <div className="grid mobile:grid-cols-1 tablet:grid-cols-3 gap-5 w-full items-center">
          {top3RegistrationPromosUsages.length > 2 && (
            <div className="text-text-1-color flex flex-col items-center gap-5">
              <div className="rounded-full border-2 border-text-1-color w-20 h-20 flex justify-center text-2xl">
                <p className="m-auto">#3</p>
              </div>
              <p>{top3RegistrationPromosUsages[2].name}</p>
              <p>{`${top3RegistrationPromosUsages[2].usageCount.toLocaleString()} استخدام`}</p>
            </div>
          )}
          {top3RegistrationPromosUsages.length > 1 && (
            <div className="text-text-1-color flex flex-col items-center gap-5">
              <div className="rounded-full border-2 border-text-1-color w-32 h-32 flex justify-center text-2xl">
                <p className="m-auto">#2</p>
              </div>
              <p>{top3RegistrationPromosUsages[1].name}</p>
              <p>{`${top3RegistrationPromosUsages[1].usageCount.toLocaleString()} استخدام`}</p>
            </div>
          )}
          {top3RegistrationPromosUsages.length > 0 && (
            <div className="text-text-1-color flex flex-col items-center gap-5">
              <div className="rounded-full border-2 border-text-1-color w-44 h-44 flex justify-center text-2xl">
                <p className="m-auto">#1</p>
              </div>
              <p>{top3RegistrationPromosUsages[0].name}</p>
              <p>{`${top3RegistrationPromosUsages[0].usageCount.toLocaleString()} استخدام`}</p>
            </div>
          )}
        </div>
        
        <div className="flex mb-10">
            <CustomLabel title="التوزيع للأكواد الترويجية الإستثمارية على مزودينها من حيث الفرص الإستثمارية" status="WARNING" />
        </div>
        <div className="grid mobile:grid-cols-1 laptop:grid-cols-3 gap-10 mb-16">
          <StatsItem
            title1="أكثر عدد لفرصة"
            title2={investmentPromosByCampaign.maxTitle}
            title3={`${investmentPromosByCampaign.maxAmount.toLocaleString()} استخدام`}
          />
          <StatsItem
            title1="متوسط عدد الاستخدامات"
            title2="لكل الفرص"
            title3={`${investmentPromosByCampaign.avgAmount.toLocaleString()} استخدام`}
          />
          <StatsItem
            title1="أقل عدد لفرصة"
            title2={investmentPromosByCampaign.minTitle}
            title3={`${investmentPromosByCampaign.minAmount.toLocaleString()} استخدام`}
          />
        </div>

        <div className="flex flex-row justify-start items-center mb-5">
          <PageTitle title={"تحليلات الإشعارات المخصصة"} isSubtitle={true} />
        </div>
        <div className="mb-10">
        <StatsItem
            title1="الإجمالي لجميع الإشعارات المخصصة المرسلة"
            title2=''
            title3={totalNotificationsCount.toLocaleString()}
          />
        </div>
        <div className="grid mobile:grid-cols-1 tablet:grid-cols-4 laptop:grid-cols-6 gap-10 mb-10">
        <div className="flex flex-col items-center" style={{color: '#365992'}}>
          <DoughnutChart
              labels={['حملة جديدة', 'الإجمالي']}
              values={[notificationsCount.newCampaings, totalNotificationsCount]}
              colors={['#365992', '#e8eaec']}
              textColor='#365992'
            />
            <p className="text-xl my-3">{notificationsCount.newCampaings}</p>
            <p className="text-xl">حملة جديدة</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#28d7e4'}}>
          <DoughnutChart
              labels={['متاح للحجز', 'الإجمالي']}
              values={[notificationsCount.preActiveCampaigns, totalNotificationsCount]}
              colors={['#28d7e4', '#e8eaec']}
              textColor='#28d7e4'
            />
            <p className="text-xl my-3">{notificationsCount.preActiveCampaigns}</p>
            <p className="text-xl">متاح للحجز</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#fb9ca2'}}>
          <DoughnutChart
              labels={['فتح باب الاستثمار', 'الإجمالي']}
              values={[notificationsCount.activeCampaigns, totalNotificationsCount]}
              colors={['#fb9ca2', '#e8eaec']}
              textColor='#fb9ca2'
            />
            <p className="text-xl my-3">{notificationsCount.activeCampaigns}</p>
            <p className="text-xl">فتح باب الاستثمار</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#4caf50'}}>
          <DoughnutChart
              labels={['إغلاق فرصة', 'الإجمالي']}
              values={[notificationsCount.completeCampaigns, totalNotificationsCount]}
              colors={['#4caf50 ', '#e8eaec']}
              textColor='#4caf50 '
            />
            <p className="text-xl my-3">{notificationsCount.completeCampaigns}</p>
            <p className="text-xl">إغلاق فرصة</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#8d6e63'}}>
          <DoughnutChart
              labels={['قبول حجز', 'الإجمالي']}
              values={[notificationsCount.acceptPreActive, totalNotificationsCount]}
              colors={['#8d6e63', '#e8eaec']}
              textColor='#8d6e63'
            />
            <p className="text-xl my-3">{notificationsCount.acceptPreActive}</p>
            <p className="text-xl">قبول حجز</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#fdd835'}}>
          <DoughnutChart
              labels={['رفض حجز', 'الإجمالي']}
              values={[notificationsCount.rejectPreActive, totalNotificationsCount]}
              colors={['#fdd835', '#e8eaec']}
              textColor='#fdd835'
            />
            <p className="text-xl my-3">{notificationsCount.rejectPreActive}</p>
            <p className="text-xl">رفض حجز</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#6a1b9a'}}>
          <DoughnutChart
              labels={['قبول استثمار', 'الإجمالي']}
              values={[notificationsCount.acceptInvestment, totalNotificationsCount]}
              colors={['#6a1b9a', '#e8eaec']}
              textColor='#6a1b9a '
            />
            <p className="text-xl my-3">{notificationsCount.acceptInvestment}</p>
            <p className="text-xl">قبول استثمار</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#d32f2f'}}>
          <DoughnutChart
              labels={['رفض استثمار', 'الإجمالي']}
              values={[notificationsCount.rejectInvestment, totalNotificationsCount]}
              colors={['#d32f2f', '#e8eaec']}
              textColor='#d32f2f'
            />
            <p className="text-xl my-3">{notificationsCount.rejectInvestment}</p>
            <p className="text-xl">رفض استثمار</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#ff8a65'}}>
          <DoughnutChart
              labels={['قبول استثمار جديد', 'الإجمالي']}
              values={[notificationsCount.acceptFirstInvestment, totalNotificationsCount]}
              colors={['#ff8a65', '#e8eaec']}
              textColor='#ff8a65'
            />
            <p className="text-xl my-3">{notificationsCount.acceptFirstInvestment}</p>
            <p className="text-xl">قبول استثمار جديد</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#ffccbc'}}>
          <DoughnutChart
              labels={['تقرير جديد', 'الإجمالي']}
              values={[notificationsCount.investorReport, totalNotificationsCount]}
              colors={['#ffccbc', '#e8eaec']}
              textColor='#ffccbc'
            />
            <p className="text-xl my-3">{notificationsCount.investorReport}</p>
            <p className="text-xl">تقرير جديد</p>
          </div>

          <div className="flex flex-col items-center" style={{color: '#455a64'}}>
          <DoughnutChart
              labels={['تقرير عام', 'الإجمالي']}
              values={[notificationsCount.investorGeneral, totalNotificationsCount]}
              colors={['#455a64', '#e8eaec']}
              textColor='#455a64'
            />
            <p className="text-xl my-3">{notificationsCount.investorGeneral}</p>
            <p className="text-xl">تقرير عام</p>
          </div>
        </div>

            
      </div>
    </div>
  );
}
