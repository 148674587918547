import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const centerTextPlugin = (textColor) => ({
  id: 'centerTextPlugin',
  afterDraw: (chart) => {
    const { ctx, data } = chart;
    ctx.save();

    const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
    const percentage = ((data.datasets[0].data[0] / total) * 100).toFixed(2) + '%';

    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

    ctx.font = 'bold 24px Effra';
    ctx.fillStyle = textColor || '#001334'; // Dynamic text color
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(percentage, centerX, centerY);

    ctx.restore();
  },
});

export default function DoughnutChart({ labels, values, colors, textColor }) {
  const defaultColors = ["#FF6384", "#36A2EB", "#FFCE56", "#FF6347", "#36A2C4", "#FFCD56", "#4BC0C0"];
  const backgroundColors = colors || defaultColors;
  const hoverBackgroundColors = colors || defaultColors;

  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: hoverBackgroundColors,
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const plugins = [centerTextPlugin(textColor)];

  return <Doughnut data={data} options={options} plugins={plugins} />;
}
