import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const generatePDFContract = async (element, spacing = 0) => {
  try {
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/jpeg");

    // Get the size of the captured element
    const elementWidth = element.offsetWidth;
    const elementHeight = element.offsetHeight;

    // Calculate dimensions with spacing
    const pdfWidth = elementWidth + spacing * 2;
    const pdfHeight = elementHeight + spacing * 2;

    // Create a new PDF document
    const pdf = new jsPDF("portrait", "pt", [pdfWidth, pdfHeight]);

    // Add image to PDF with spacing
    pdf.addImage(imgData, "JPEG", spacing, spacing, elementWidth, elementHeight);

    return pdf.output("blob");
  } catch (error) {
    console.error("Cannot generate PDF: ", error);
    return null;
  }
};

export default generatePDFContract;
