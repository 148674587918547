import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import DashboardItem from "./components/DashboardItem";
import DashboardButton from "./components/DashboardButton";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import getInvestmentsCount from "../../lib/investments/getInvestmentsCount";
import getUsersCount from "../../lib/users/getUsersCount";
import getCampaigns from "../../lib/campaigns/getCampaigns";
import PageLoader from "../../components/PageLoader";
import getCampaignsInvestmentsAmount from "../../lib/campaigns/getCampaignsInvestmentsAmount";
import { formatAvgTime } from "../../utils/formatAvgTime";

export default function HomePage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [usersCount, setUsersCount] = useState(0);
  // const [activeCampaignsCount, setActiveCampaignsCount] = useState(0);
  // const [completedCampaignsCount, setCompletedCampaignsCount] = useState(0);
  const [pendingInvestmentsCount, setPendingInvestmentsCount] = useState(0);
  const [overallAvgTime, setOverallAvgTime] = useState(null);
  const [walletTotal, setWalletTotal] = useState(0);

  useEffect(() => {
    const fetchInsights = async () => {
      const users = await getUsersCount();
      // const activeCampaigns = await getCampaignsCount("ACTIVE");
      // const completedCampaigns = await getCampaignsCount("COMPLETED");
      const pendingInvestments = await getInvestmentsCount("PENDING");
      const campaignsData = await getCampaigns();
      const walletAmounts = await getCampaignsInvestmentsAmount();

      const tempArr = [];
      
      campaignsData.forEach((data) => {
        if(data.createdAt && data.closedAt){
          const createdAt = data.createdAt.toDate();
          const closedAt = data.closedAt.toDate();
          const timeDifference = closedAt.getTime() - createdAt.getTime();
          tempArr.push(timeDifference);
        }
      })


      const totalAvgTime = tempArr.reduce((acc, cur) => acc + cur, 0) / tempArr.length;
      

      setUsersCount(users);
      // setActiveCampaignsCount(activeCampaigns);
      // setCompletedCampaignsCount(completedCampaigns);
      setPendingInvestmentsCount(pendingInvestments);
      setOverallAvgTime(totalAvgTime);
      setWalletTotal(walletAmounts);
      setIsLoading(false);
    }

    fetchInsights();
  }, [])
  return isLoading 
  ? <PageLoader />
  : (
    <div>
      <Navbar />
      <div className="flex flex-row items-center justify-between mb-10">
        <PageTitle title="الأرقام والتحاليل" isSubtitle={true} />
        {/* <div className="h-1 bg-text-3-color rounded-full grow mr-5"></div> */}
      </div>
      <div className="mb-20 grid gap-10 desktop:grid-cols-4 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1">
        <DashboardItem title="عدد المستخدمين" value={usersCount.toLocaleString()} />
        {/* <DashboardItem title="عدد الفرص المكتملة" value={completedCampaignsCount.toLocaleString()} />
        <DashboardItem title="عدد الفرص النشطة" value={activeCampaignsCount.toLocaleString()} /> */}
        <DashboardItem title="طلبات الإستثمار الجديدة" value={pendingInvestmentsCount.toLocaleString()} />
        <DashboardItem title="متوسط وقت إغلاق الفرص" value={formatAvgTime(overallAvgTime)} />
        <DashboardItem title="قيمة المحفظة العقارية" value={`${walletTotal.toLocaleString()} ريال`} />
      </div>
      <div className="flex flex-row items-center justify-between mb-10">
        <PageTitle title="الإجراءات والتحكم" isSubtitle={true} />
        {/* <div className="h-1 bg-text-3-color rounded-full grow mr-5"></div> */}
      </div>
      <div className="mb-20 grid gap-10 grid-cols-2">
        <DashboardButton title="إدارة المستخدمين" handleClick={() => navigate('/users_management')} />
        <DashboardButton title="إدارة العقارات" handleClick={() => navigate('/opportunities_management')} />
        <DashboardButton title="إدارة المستثمرين" handleClick={() => navigate('/investors_management')} />
        <DashboardButton title="إدارة الأكواد الترويجية للمسجلين" handleClick={() => navigate('/registration_promos')} />
        <DashboardButton title="إدارة الأكواد الترويجية الإستثمارية" handleClick={() => navigate('/investment_promos')} />
        <DashboardButton title="إحصائيات وتحليلات" handleClick={() => navigate('/statistics')} />
        <DashboardButton title="المحفظة العقارية" handleClick={() => navigate('/opportunity_investment_records')} />
        <DashboardButton title="بيانات مشتركة" handleClick={() => navigate('/shared_info_details')} />
        <DashboardButton title="نظام التصويت" handleClick={() => navigate('/announcements')} />
      </div>
    </div>
  );
}
