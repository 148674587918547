import React from "react";

export default function PageTitle({ title, isSubtitle }) {
  return !isSubtitle ? (
    <p className="text-text-1-color font-bold text-xl">{title}</p>
  ) : (
    <div className="flex gap-5 items-center">
      <div className="relative m-auto">
        <div className="w-5 h-5 bg-secondary-color absolute z-50 bottom-2.5 right-2.5"></div>
        <div className="w-5 h-5 bg-text-3-color"></div>
      </div>
      <p className="text-text-1-color font-bold text-lg my-5">{title}</p>
    </div>
  );
}
