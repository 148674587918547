import { Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "./pages/login/Login";
import HomePage from "./pages/home/Home";
import OpportunitiesManagementPage from "./pages/opportunities_management/OpportunitiesManagement";
import CreateInvestmentOpportunityPage from "./pages/create_investment_opportunity/CreateInvestmentOpportunity";
import EditInvestmentOpportunityPage from "./pages/edit_investment_opportunity/EditInvestmentOpportunity";
import InvestmentsRequestsPage from "./pages/investments_requests/InvestmentsRequests";
import OpportunityInvestmentRecordsPage from "./pages/opportunity_investment_records/OpportunityInvestmentRecords";
import InvestmentOpportunityReportsPage from "./pages/investment_opportunity_reports/InvestmentOpportunityReports";
import UsersManagementPage from "./pages/users_management/UsersManagement";
import InvestmentOpportunityDetailsPage from "./pages/investment_opportunity_details/InvestmentOpportunityDetails";
import AuthProvider from "./contexts/AuthContext";
import PrivateRoutes from "./components/PrivateRoutes";
import InvestorsManagementPage from "./pages/investors_management/InvestorsManagement";
import GeneralReportsPage from "./pages/general_reports/GeneralReports";
import NotificationsManagement from "./pages/notifications_management/NotificationsManagement";
import RegistrationPromosManagement from "./pages/registration_promos_management/RegistrationPromosManagement";
import RegistrationPromoUsages from "./pages/registration_promo_usages/RegistrationPromoUsages";
import InvestmentPromosManagement from "./pages/investment_promos_management/InvestmentPromosManagement";
import InvestmentPromoUsages from "./pages/investment_promo_usages/InvestmentPromoUsages";
import ReservationRecords from "./pages/reservation_records/ReservationRecords";
import SharedInfoDetailsPage from "./pages/shared_info_details/SharedInfoDetails";
import AnnouncementsPage from "./pages/announcements/Announcements";
import StatisticsPage from "./pages/statistics/Statistics";
import { useEffect } from "react";
import AutomatedContract from "./pages/investors_management/AutomatedContract";
import AutomatedContractPage from "./pages/investors_management/AutomatedContract";

function App() {
  // scroll all the way to top
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <AuthProvider>
        <div className="bg-primary-color h-5 w-full fixed top-0 z-50"></div>
        <div className="bg-primary-color h-5 w-full fixed bottom-0 z-50"></div>
        <div className="px-11 py-16">
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<HomePage />} exact />
              <Route
                path="/opportunities_management"
                element={<OpportunitiesManagementPage />}
              />
              <Route
                path="/create_investment_opportunity"
                element={<CreateInvestmentOpportunityPage />}
              />
              <Route
                path="/investment_opportunity_details/:id"
                element={<InvestmentOpportunityDetailsPage />}
              />
              <Route
                path="/edit_investment_opportunity/:id"
                element={<EditInvestmentOpportunityPage />}
              />
              <Route
                path="/investments_requests/:campaignId"
                element={<InvestmentsRequestsPage />}
              />
              <Route
                path="/opportunity_investment_records"
                element={<OpportunityInvestmentRecordsPage />}
              />
              <Route
                path="/opportunity_investment_reports/:campaignId"
                element={<InvestmentOpportunityReportsPage />}
              />
              <Route
                path="/users_management"
                element={<UsersManagementPage />}
              />
              <Route
                path="/investors_management"
                element={<InvestorsManagementPage />}
              />
              <Route
                path="/general_reports"
                element={<GeneralReportsPage />}
              />
              <Route
                path="/notifications_management"
                element={<NotificationsManagement />}
              />
              <Route
                path="/registration_promos"
                element={<RegistrationPromosManagement />}
              />
              <Route
                path="/registration_promos/:promoId"
                element={<RegistrationPromoUsages />}
              />
              <Route
                path="/investment_promos/"
                element={<InvestmentPromosManagement />}
              />
              <Route
                path="/investment_promos/:promoId"
                element={<InvestmentPromoUsages />}
              />
              <Route
                path="/reservation_records/:campaignId"
                element={<ReservationRecords />}
              />
              <Route
                path="/shared_info_details"
                element={<SharedInfoDetailsPage />}
              />
              <Route
                path="/announcements"
                element={<AnnouncementsPage />}
              />
              <Route
                path="/statistics"
                element={<StatisticsPage />}
              />
              <Route 
                path="/automated_contract/:userId"
                element={<AutomatedContractPage />}
              />
            </Route>
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </div>
      </AuthProvider>
    </>
  );
}

export default App;
