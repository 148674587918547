import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getVotingCount = async (id) => {
    const votingLogsCollection = collection(firestore, "announcementVotingLogs");
    const q = query(votingLogsCollection, where("selectedOptionId", "==", id));
    const querySnapshot = await getDocs(q);

    console.log(querySnapshot.size);

    return querySnapshot.size;
}

export default getVotingCount;
