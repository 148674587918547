import React from "react";

const LoadingOverlay = ({ show }) => {
  return show ? (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50"
    >
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-color z-50"></div>
    </div>
  ) : null;
};

export default LoadingOverlay;
