import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestmentPromosUsagesByUser = async (id) => {
    const invPromosUsageCollection = collection(firestore, "investmentPromosUsage");
    const q = query(invPromosUsageCollection, where("userId", "==", id));
    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
    
}
export default getInvestmentPromosUsagesByUser;