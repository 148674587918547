import React, { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';

// Define a component to handle the image loading state
export const ImagePreview = ({ src, alt, className }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div className={`relative ${className}`}>
      {loading && (
        <div className="flex flex-col items-center justify-center w-full p-10">
          {/* You can use any loading animation or text */}
          <div className='w-full'>
          <RotatingLines
      strokeColor="#E7C067"
      strokeWidth="5"
      animationDuration="0.50"
      width="96"
      visible={true}
    />
          </div>
        </div>
      )}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center">
          {/* You can use any error message or placeholder image */}
          <p>Error loading image</p>
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`${loading || error ? 'hidden' : ''} w- h-full object-contain rounded-2xl`}
        onLoad={() => setLoading(false)}
        onError={() => setError(true)}
      />
    </div>
  );
};
