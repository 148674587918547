import React, { useEffect, useState } from "react";
import PageLoader from "../../components/PageLoader";
import getSharedInfo from "../../lib/shared_info/getSharedInfo";
import { ToastContainer } from "react-toastify";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import CustomDropdown from "../../components/CustomDropdown";
import { BANKS_NAMES } from "../../helpers/banks";
import notify from "../../utils/notify";
import updateSharedInfo from "../../lib/shared_info/updateSharedInfo";
import { useNavigate } from "react-router-dom";
import FilePicker from "../../components/FilePicker";
import uploadImage from "../../lib/firebase_storage/uploadImage";
import { COUNTRY_KEYS } from "../../helpers/country_keys";
import generateId from "../../utils/generateId";

export default function SharedInfoDetailsPage() {
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [walletAnnualROI, setWalletAnnualROI] = useState("");
  const [walletDuration, setWalletDuration] = useState("");
  const [capitalRecoveryPeriod, setCapitalRecoveryPeriod] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [supportWhatsapp, setSupportWhatsapp] = useState("");
  const [supportCall, setSupportCall] = useState("");
  const [countryCode, setCountryCode] = useState(COUNTRY_KEYS['SA'].key);
  const [bank, setBank] = useState("ALINMA");
  const [beneficiary, setBeneficiary] = useState("");
  const [iban, setIban] = useState("");
  const [disclosureDocumentFile, setDisclosureDocumentFile] = useState(null);
  const [disclosureDocumentURL, setDisclosureDocumentURL] = useState("");

  useEffect(() => {
    const fetchSharedInfo = async () => {
      const sharedInfo = await getSharedInfo();
      setWalletAnnualROI(sharedInfo.walletAnnualROI ?? "");
      setWalletDuration(sharedInfo.walletDuration ?? "");
      setCapitalRecoveryPeriod(sharedInfo.capitalRecoveryPeriod ?? "");
      setSupportEmail(sharedInfo.supportEmail ?? "");
      setSupportWhatsapp(sharedInfo.supportWhatsapp ?? "");
      setSupportCall(sharedInfo.supportCall.substring(4, sharedInfo.supportCall.length) ?? "");
      setBank(sharedInfo.qaswarahBankAccount.bank ?? "");
      setBeneficiary(sharedInfo.qaswarahBankAccount.beneficiary ?? "");
      setIban(sharedInfo.qaswarahBankAccount.IBAN ?? "");
      setDisclosureDocumentURL(sharedInfo.disclosureDocumentURL);
      setIsPageLoading(false);
    };

    fetchSharedInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!disclosureDocumentFile && !disclosureDocumentURL) {
      notify("ERROR", "الرجاء إرفاق وثيقة الإفصاح.");
      setIsLoading(false);
      return;
    }
    if (!supportCall.startsWith("5") || !supportWhatsapp.startsWith("5")) {
      notify("ERROR", "رقم الجوال والواتس يجب أن يبدأوا بـ5");
      setIsLoading(false);
      return;
    }
    const uniqueId = generateId();
    const url = !disclosureDocumentFile
      ? disclosureDocumentURL
      : await uploadImage(
          disclosureDocumentFile,
          "sharedInfo",
          "Disclosure Document",
          uniqueId
        );
    await updateSharedInfo({
      walletAnnualROI: walletAnnualROI,
      walletDuration: walletDuration,
      capitalRecoveryPeriod: capitalRecoveryPeriod,
      supportEmail: supportEmail,
      supportWhatsapp: supportWhatsapp,
      supportCall: countryCode + supportCall,
      disclosureDocumentURL: url,
      qaswarahBankAccount: {
        bank: bank,
        beneficiary: beneficiary,
        IBAN: iban,
      },
    });
    navigate(0);
    try {
    } catch (e) {
      console.error("Cannot update: ", e);
      notify("ERROR", "حصل خطأ أثناء تحديث البيانات.");
      setIsLoading(false);
    }
  };

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />

      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="mb-16">
          <div className="flex flex-row justify-start items-center mb-10">
            <PageTitle title={"بيانات المحفظة العقارية"} isSubtitle={true} />
          </div>
          <div className="flex mobile:flex-col tablet:flex-row gap-10 mb-10">
            <div className="w-full">
              <label className="text-text-2-color font-bold">
                العائد السنوي للمحفظة
              </label>
              <CustomInput
                type="text"
                value={walletAnnualROI}
                setValue={setWalletAnnualROI}
                placeholder="ادخل العائد السنوي للمحفظة..."
                customStyle="mt-5"
                isRequired={true}
              />
            </div>
            <div className="w-full">
              <label className="text-text-2-color font-bold">
                مدة المحفظة العقارية
              </label>
              <CustomInput
                type="text"
                value={walletDuration}
                setValue={setWalletDuration}
                placeholder="ادخل مدة المحفظة العقارية..."
                customStyle="mt-5"
                isRequired={true}
              />
            </div>
          </div>
          <div className="w-full">
            <label className="text-text-2-color font-bold">
              فترة استرداد رأس المال المتوقعة
            </label>
            <CustomInput
              type="text"
              value={capitalRecoveryPeriod}
              setValue={setCapitalRecoveryPeriod}
              placeholder="ادخل فترة استرداد رأس المال المتوقعة..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
        </div>

        <div className="mb-16">
          <div className="flex flex-row justify-start items-center mb-10">
            <PageTitle title={"الدعم الفني"} isSubtitle={true} />
          </div>
          <div className="flex mobile:flex-col tablet:flex-row gap-10 mb-10">
            <div className="w-full">
              <label className="text-text-2-color font-bold">بريد الدعم</label>
              <CustomInput
                type="text"
                value={supportEmail}
                setValue={setSupportEmail}
                placeholder="ادخل بريد الدعم"
                customStyle="mt-5"
                isRequired={true}
              />
            </div>
            <div className="w-full">
              <label className="text-text-2-color font-bold">رقم الواتس</label>
              <div className="flex gap-5">
                <CustomInput
                  type="text"
                  value={supportWhatsapp}
                  setValue={setSupportWhatsapp}
                  placeholder="ادخل رقم الواتس..."
                  customStyle="mt-5"
                  isRequired={true}
                />
                <CustomInput
                  type="text"
                  value={countryCode}
                  setValue={setCountryCode}
                  placeholder="..."
                  customStyle="mt-5 text-center w-2/5"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <label className="text-text-2-color font-bold">رقم الاتصال</label>
            <div className="flex gap-5">
              <CustomInput
                type="text"
                value={supportCall}
                setValue={setSupportCall}
                placeholder="ادخل رقم الاتصال..."
                customStyle="mt-5"
                isRequired={true}
              />
              <CustomInput
                type="text"
                value={countryCode}
                setValue={setCountryCode}
                placeholder="..."
                customStyle="mt-5 text-center w-1/5"
                isRequired={true}
                isDisabled={true}
              />
            </div>
          </div>
        </div>

        <div className="mb-16">
          <div className="flex flex-row justify-start items-center mb-10">
            <PageTitle title={"وثيقة الإفصاح"} isSubtitle={true} />
          </div>

          <FilePicker
            selectedFile={disclosureDocumentFile}
            setSelectedFile={setDisclosureDocumentFile}
            customStyle="w-full w-2/3"
            fileUrl={disclosureDocumentURL}
            setFileUrl={setDisclosureDocumentURL}
            modalTitle="وثيقة الإفصاح"
          />
        </div>

        <div className="mb-16">
          <div className="flex flex-row justify-start items-center mb-10">
            <PageTitle
              title={"الحساب البنكي لإستقبال الحوالات"}
              isSubtitle={true}
            />
          </div>
          <div className="flex mobile:flex-col tablet:flex-row gap-10 mb-10">
            <div className="w-full">
              <label className="text-text-2-color font-bold">البنك</label>
              <CustomDropdown
                options={BANKS_NAMES}
                customStyle="mt-5 mb-5"
                listName="banksNames"
                inputName="bank"
                value={bank}
                setValue={setBank}
                isRequired={true}
                defaultValue={bank}
              />
            </div>
            <div className="w-full">
              <label className="text-text-2-color font-bold">اسم الحساب</label>
              <CustomInput
                type="text"
                value={beneficiary}
                setValue={setBeneficiary}
                placeholder="ادخل اسم الحساب..."
                customStyle="mt-5"
                isRequired={true}
              />
            </div>
          </div>
          <div className="w-full">
            <label className="text-text-2-color font-bold">الآيبان</label>
            <CustomInput
              type="text"
              value={iban}
              setValue={setIban}
              placeholder="ادخل الآيبان..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
        </div>
        <CustomButton title="تحديث" customStyle="p-4" isLoading={isLoading} />
      </form>
    </div>
  );
}
