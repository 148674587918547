import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomTextArea from "../../components/CustomTextArea";
import CustomInput from "../../components/CustomInput";
import CustomModal from "../../components/CustomModal";
import PageLoader from "../../components/PageLoader";
import getNotificationsByType from "../../lib/notifications/getNotificationsByType";
import createNotification from "../../lib/notifications/createNotification";
import { Timestamp } from "firebase/firestore";
import notify from "../../utils/notify";
import { useNavigate } from "react-router-dom";
import getInvestmentByStatus from "../../lib/investments/getInvestmentByStatus";
import generateId from "../../utils/generateId";
import formatTimeTo12Hour from "../../utils/formatTime";

export default function NotificationsManagement() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const tableHeaders = ["العنوان", "الرسالة", "تاريخ الإرسال", "وقت الإرسال"];
  useEffect(() => {
    const fetchNotifications = async () => {
      const fetchedNotifications = await getNotificationsByType(
        "INVESTOR_GENERAL_NOTIFICATION"
      );
      // Create a Set to store unique topicIds
      const uniqueTopicIds = new Set();

      // Filter out duplicates
      const uniqueNotifications = fetchedNotifications.filter(
        (notification) => {
          if (uniqueTopicIds.has(notification.topicId)) {
            return false;
          } else {
            uniqueTopicIds.add(notification.topicId);
            return true;
          }
        }
      );

      setNotifications(uniqueNotifications);
      setIsPageLoading(false);
    };
    fetchNotifications();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Get approvedInvestments
      const approvedInvestments = await getInvestmentByStatus("APPROVED");

      // Create a Set to keep track of unique user IDs
      const uniqueUserIds = new Set();

      // Generate notification topic id
      const topicId = generateId();

      const notificationPromises = approvedInvestments.map(
        async (investment) => {
          if (!uniqueUserIds.has(investment.userId)) {
            uniqueUserIds.add(investment.userId);

            return createNotification({
              title: title,
              message: message,
              data: { type: "INVESTOR_GENERAL_NOTIFICATION" },
              toUser: investment.userId,
              topicId: topicId,
              isRead: false,
              createdAt: Timestamp.now(),
            });
          }
        }
      );

      // Wait for all notifications to be sent
      await Promise.all(notificationPromises);

      // Navigate after all notifications are sent
      navigate(0);
    } catch (error) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ.");
      console.error("Error: ", error);
    }
  };

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <Navbar />
      <div className="flex flex-row justify-between items-center mb-16">
        <PageTitle title="الإشعارات" />
        <div className="w-60">
          <CustomButton
            title="إشعار جديد"
            customStyle="p-3"
            handleClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {notifications.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.title}</td>
                      <td className="px-6 py-4">{column.message}</td>
                      <td className="px-6 py-4">
                        {column.createdAt.toDate().toISOString().split("T")[0]}
                      </td>
                      <td className="px-6 py-4" dir="ltr">
                        {formatTimeTo12Hour(
                          column.createdAt.toDate().toISOString()
                        )}
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="إشعار جديد"
        height="h-156"
        content={
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-2 gap-10">
              <div>
                <label className="text-text-2-color font-bold">
                  عنوان الإشعار
                </label>
                <CustomInput
                  type="text"
                  value={title}
                  setValue={setTitle}
                  placeholder="ادخل عنوان الإشعار..."
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                />
              </div>
            </div>
            <label className="text-text-1-color font-bold text-xl">
              الرسالة
            </label>
            <CustomTextArea
              value={message}
              setValue={setMessage}
              placeholder="ادخل الرسالة المخصصة للإشعار..."
              customStyle="h-60 mt-5 mb-10"
              isRequired={true}
            />
            <div className="bg-ice-color rounded-3xl p-10 mb-5">
              <p className="text-text-1-color">
                ملاحظة مهمة: سيتم إرسال الإشعار فقط للمستثمرين الذين تم قبول
                إستثمارهم في حملات سابقة
              </p>
            </div>
            <CustomButton
              title="إرسال"
              customStyle="py-5"
              isLoading={isLoading}
            />
          </form>
        }
      />
    </div>
  );
}
