import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import { useNavigate } from "react-router-dom";
import getCampaigns from "../../lib/campaigns/getCampaigns";
import OPP_STATUS from "../../helpers/opportunity_status";
import updateCampaign from "../../lib/campaigns/updateCampaign";
import getInvestmentsCountByCampaignId from "../../lib/investments/getInvestmentsCountByCampaignId";
import { Timestamp } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import notify from "../../utils/notify";
import PageLoader from "../../components/PageLoader";
import createNotification from "../../lib/notifications/createNotification";
import getInvestmentsByCampaignId from "../../lib/investments/getInvestmentsByCampaignId";
import getUsers from "../../lib/users/getUsers";

export default function OpportunitiesManagementPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedInvestments, setSelectedInvestments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    const fetchCampaigns = async () => {
      const fetchedCampaigns = await getCampaigns();
      // Set the values
      setCampaigns(fetchedCampaigns);
      setIsPageLoading(false);
    };
    fetchCampaigns();
  }, []);
  const campaignsTableHeaders = [
    "اسم العقار",
    "الموقع",
    "مبلغ الإستثمار",
    // "العائد السنوي",
    "مرحلة المشروع",
    "وقت الإنشاء",
    "وقت الإغلاق",
    "الحالة",
    "إجراءات",
  ];

  const handleDeleteInvestment = async () => {
    setIsLoading(true);
    const investmentsInCampaign = await getInvestmentsCountByCampaignId(
      selectedCampaign.id,
      "APPROVED"
    );
    if (investmentsInCampaign <= 0) {
      await updateCampaign(selectedCampaign.id, {
        status: selectedCampaign.status === "DELETED" ? "ACTIVE" : "DELETED",
      });
      navigate(0);
    } else {
      notify(
        "ERROR",
        "يوجد سجل استثمارات في هذه الحملة, يرجى حذف جميع السجلات لحذف الحملة."
      );
      setIsLoading(false);
    }
  };

  const handleReservationStatus = async () => {
    setIsLoading(true);
    try {
      await updateCampaign(selectedCampaign.id, {
        status: "PREACTIVE",
      });
      // Send notification to all users
      const allUsers = await getUsers();
      notify(
        "INFO",
        "الرجاء الإنتظار وعدم الخروج، جاري إرسال الإشعار للمستخدمين..."
      );
      for (var i = 0; i < allUsers.length; i++) {
        await createNotification({
          title: selectedCampaign.title,
          message: `تم إضافة مشروع ${selectedCampaign.title}، يمكنك الآن حجز استثمارك`,
          data: { type: "PREACTIVE_CAMPAIGN" },
          toUser: allUsers[i].id,
          isRead: false,
          createdAt: Timestamp.now(),
        });
      }
    } catch (e) {
      notify("ERROR", "حصل خطأ، الرجاء المحاولة لاحقاً.");
    }
    navigate(0);
  };

  const handleCompleteInvestment = async () => {
    setIsLoading(true);
    try {
      await updateCampaign(selectedCampaign.id, {
        status: selectedCampaign.status === "ACTIVE" ? "COMPLETED" : "ACTIVE",
        closedAt: Timestamp.now(),
      });
      const allUsers = await getUsers();
      notify(
        "INFO",
        "الرجاء الإنتظار وعدم الخروج، جاري إرسال الإشعار للمستخدمين..."
      );
      // This checks if the campaign is active before being updated.
      if (selectedCampaign.status === "PREACTIVE") {
        for (var i = 0; i < allUsers.length; i++) {
          await createNotification({
            title: selectedCampaign.title,
            message: `تم فتح باب الإستثمار لمشروع ${selectedCampaign.title}`,
            data: { type: "ACTIVE_CAMPAIGN" },
            toUser: allUsers[i].id,
            isRead: false,
            createdAt: Timestamp.now(),
          });
        }
        navigate(0);
      } else if (selectedCampaign.status === "ACTIVE") {
        for (var i = 0; i < allUsers.length; i++) {
          await createNotification({
            title: selectedCampaign.title,
            message: `نبارك للمستثمرين تم إغلاق فرصة ${selectedCampaign.title}`,
            data: { type: "COMPLETE_CAMPAIGN" },
            toUser: allUsers[i].id,
            isRead: false,
            createdAt: Timestamp.now(),
          });
        }
        navigate(0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleHiddenStatus = async () => {
    setIsLoading(true);
    try {
      await updateCampaign(selectedCampaign.id, {
        status: "HIDDEN",
      });
    } catch (e) {
      notify("ERROR", "حصل خطأ، الرجاء المحاولة لاحقاً.");
    }
    navigate(0);
  };

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      {/* Opportunities Management */}
      <div className="flex flex-row justify-between items-center mb-10">
        <PageTitle title="العقارات" />
        <div className="w-60">
          <CustomButton
            title="إضافة عقار +"
            customStyle="p-2"
            handleClick={() => navigate("/create_investment_opportunity")}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {campaignsTableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {campaigns.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.title}</td>
                      <td className="px-6 py-4">
                        {column.locationDescription}
                      </td>
                      <td className="px-6 py-4">
                        {column.investmentMaxAmount.toLocaleString()} ريال
                      </td>
                      {/* <td className="px-6 py-4">{column.investmentROI}%</td> */}
                      <td className="px-6 py-4">{column.projectStatus}</td>
                      <td className="px-6 py-4">
                        {column.createdAt.toDate().toISOString().split("T")[0]}
                      </td>
                      <td className="px-6 py-4">
                        {column.closedAt !== undefined
                          ? column.status === "COMPLETED"
                            ? column.closedAt
                                .toDate()
                                .toISOString()
                                .split("T")[0]
                            : " - "
                          : " - "}
                      </td>
                      <td className="px-6 py-4">{OPP_STATUS[column.status]}</td>
                      <td className="px-6 py-4">
                        <CustomButton
                          title="إجراءات"
                          customStyle="px-8 py-2"
                          handleClick={async () => {
                            setSelectedCampaign(column);
                            const fetchedInvestments =
                              await getInvestmentsByCampaignId(
                                column.id,
                                "APPROVED"
                              );
                            setSelectedInvestments(fetchedInvestments);
                            setShowModal(true);
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="إجراءات"
        content={
          <div>
            <div className="py-3">
              <CustomButton
                title="عرض تفاصيل العقار"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(
                    `/investment_opportunity_details/${selectedCampaign.id}`
                  )
                }
              />
            </div>
            <div className="py-3">
              <CustomButton
                title="تعديل بيانات العقار"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(
                    `/edit_investment_opportunity/${selectedCampaign.id}`
                  )
                }
              />
            </div>

            {selectedCampaign.status !== "DELETED" ? (
              <div className="py-3">
                <CustomButton
                  title="حذف الفرصة"
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleDeleteInvestment}
                />
              </div>
            ) : (
              ""
            )}

            {selectedCampaign.status === "DELETED" ? (
              <div className="py-3">
                <CustomButton
                  title="تغيير الحالة إلى: غير مدرجة"
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleHiddenStatus}
                />
              </div>
            ) : selectedCampaign.status === "HIDDEN" ? (
              <div className="py-3">
                <CustomButton
                  title="تغيير الحالة إلى: متاح للحجز"
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleReservationStatus}
                />
              </div>
            ) : selectedCampaign.status === "PREACTIVE" ? (
              <div className="py-3">
                <CustomButton
                  title="تغيير الحالة إلى: نشط"
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleCompleteInvestment}
                />
              </div>
            ) : selectedCampaign.status === "ACTIVE" ? (
              <div className="py-3">
                <CustomButton
                  title="تغيير الحالة إلى: مكتمل"
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleCompleteInvestment}
                />
              </div>
            ) : (
              ""
            )}
            <div className="py-3">
              <CustomButton
                title="سجل التقارير"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(
                    `/opportunity_investment_reports/${selectedCampaign.id}`
                  )
                }
              />
            </div>
            <div className="py-3">
              <CustomButton
                title="سجل الحجوزات"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(`/reservation_records/${selectedCampaign.id}`)
                }
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
