import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const generatePDF = async (element) => {
  try {
      // Capture the component
  const canvas = await html2canvas(element, { scale: 2 });
  const imgData = canvas.toDataURL("image/png");

  // Specify the PDF size and orientation (A4 paper size in landscape)
  const pdfWidth = 297; // A4 width in mm (landscape)
  const pdfHeight = 210; // A4 height in mm (landscape)
  const pdf = new jsPDF("landscape", "mm", "a4");

  // Calculate the width and height based on the aspect ratio of the canvas
  const aspectRatio = canvas.width / canvas.height;
  let imgWidth = pdfWidth;
  let imgHeight = pdfWidth / aspectRatio;

  // Adjust image height to fit exactly within PDF height
  if (imgHeight > pdfHeight) {
    imgHeight = pdfHeight;
    imgWidth = pdfHeight * aspectRatio;
  }

  // Add image to PDF
  const xOffset = (pdfWidth - imgWidth) / 2;
  const yOffset = (pdfHeight - imgHeight) / 2;
  pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

  const pdfBlob = pdf.output("blob");
  return pdfBlob;
  } catch (error) {
    console.error('Cannot upload PDF: ', error);
  }
};

export default generatePDF;
