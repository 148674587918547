import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getSharedInfo = async () => {
    const sharedInfoCollection = collection(firestore, "sharedInfo");
    const q = query(sharedInfoCollection);
    const querySnapshot = await getDocs(q);

    const sharedInfoData = querySnapshot.docs.map((data) => {
        var obj = data.data();
        obj['id'] = data.id;
        return obj
    });
    
    return sharedInfoData[0];
}
export default getSharedInfo;