import { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import AddUserForm from "./components/AddUserForm";
import EditUserForm from "./components/EditUserForm";
import ViewUserInfoForm from "./components/ViewUserInfoForm";
import getUsers from "../../lib/users/getUsers";
import { INCOME } from "../../helpers/income";
import { NATIONALITIES } from "../../helpers/nationalities";
import { REGIONS } from "../../helpers/regions";
import updateUser from "../../lib/users/updateUser";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import notify from "../../utils/notify";
import { exportExcelData } from "../../utils/exportExcelData";
import { formatDateNow } from "../../utils/formatDateNow";
import { sortList } from "../../utils/sortList";
import CustomLabel from "../../components/CustomLabel";
import LoadingOverlay from "../../components/LoadingOverlay";

export default function UsersManagementPage() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await getUsers();
      const sortedUsers = sortList(fetchedUsers, "isContacted");
      setUsers(sortedUsers);
    };
    fetchUsers();
  }, []);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUserActionsModal, setShowUserActionsModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showViewUserModal, setShowViewUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const navigate = useNavigate();
  const usersTableRef = useRef(null);
  const handleOnExport = () => {
    let usersData = [];
    for (var i = 0; i < users.length; i++) {
      const userObj = {
        "اسم المستخدم": users[i].name,
        "رقم الجوال": users[i].phone,
        المدينة: REGIONS["SA"][users[i].city],
        الجنسية: NATIONALITIES[users[i].nationality],
        "الدخل الشهري": INCOME[users[i].income],
        "حساب بنكي": users[i].bankAccounts.length > 0 ? "يوجد" : "لا يوجد",
        الحالة: users[i].isBanned ? "محظور" : "متاح",
        "تاريخ الإنشاء": users[i].createdAt
          .toDate()
          .toISOString()
          .split("T")[0],
        "حالة التواصل": users[i].isContacted ? "تم التواصل" : "لم يتم التواصل",
      };
      usersData.push(userObj);
    }
    const now = formatDateNow();
    exportExcelData(usersData, "Qaswarah Users", `Qaswarah_Users_${now}.xlsx`);
  };
  const tableHeaders = [
    "اسم المستخدم",
    "رقم الجوال",
    "المدينة",
    "الجنسية",
    "الدخل الشهري",
    "حساب بنكي",
    "الحالة",
    "تاريخ الإنشاء",
    "إجراء التواصل",
    "إجراءات",
  ];

  const handleBlock = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const userObj = { isBanned: selectedUser.isBanned === true ? false : true };
    try {
      await updateUser(selectedUser.id, userObj);
      navigate(0);
    } catch (e) {
      console.log(e);
      notify("ERROR", "حصل خطأ أثناء حظر المستخدم، حاول مرة أخرى.");
      setIsLoading(false);
    }
  };

  const handleUserContact = async (user) => {
    try {
      setShowOverlay(true);
      await updateUser(user.id, { isContacted: true });
      const updatedUsers = users.map((u) =>
        u.id === user.id ? { ...u, isContacted: true } : u
      );
      setUsers(updatedUsers);
      setShowOverlay(false);
      // navigate(0);
    } catch (error) {
      notify("ERROR", "حصل خطأ، حاول مرة أخرى.");
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <LoadingOverlay show={showOverlay} />
      <ToastContainer />
      <Navbar />
      <div className="flex flex-row justify-between items-center mb-16">
        <PageTitle title="إدارة المستخدمين" />
        <div className="w-60">
          <CustomButton
            title="إضافة مستخدم"
            customStyle="p-2 mb-5"
            handleClick={() => setShowAddUserModal(true)}
          />
          <CustomButton
            title="استخراج اكسل"
            customStyle="p-2"
            handleClick={handleOnExport}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm" ref={usersTableRef}>
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {users.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.name}</td>
                      <td className="px-6 py-4" dir="ltr">
                        {column.phone}
                      </td>
                      <td className="px-6 py-4">
                        {REGIONS["SA"][column.city]}
                      </td>
                      <td className="px-6 py-4">
                        {NATIONALITIES[column.nationality]}
                      </td>
                      <td className="px-6 py-4">{INCOME[column.income]}</td>
                      {column.bankAccounts.length > 0 ? (
                        <td className="px-6 py-4">يوجد</td>
                      ) : (
                        <td className="px-6 py-4">لا يوجد</td>
                      )}
                      {column.isBanned === true ? (
                        <td className="px-6 py-4">محظور</td>
                      ) : (
                        <td className="px-6 py-4">متاح</td>
                      )}
                      <td className="px-6 py-4">
                        {column.createdAt.toDate().toISOString().split("T")[0]}
                      </td>
                      <td className="px-6 py-4">
                        {column.isContacted ? (
                          <CustomLabel title="تم التواصل" status="SUCCESS" />
                        ) : (
                          <CustomButton
                            title="إتمام التواصل"
                            type="outline"
                            customStyle="px-4 py-2"
                            handleClick={() => {
                              handleUserContact(column);
                            }}
                          />
                        )}
                      </td>
                      <td className="px-6 py-4">
                        <CustomButton
                          title="إجراءات"
                          customStyle="px-8 py-2"
                          handleClick={() => {
                            setShowUserActionsModal(true);
                            setSelectedUser(column);
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* ------------------------------ Modals -------------------------------------- */}

      {/* Show User Actions Modal */}
      <CustomModal
        showModal={showUserActionsModal}
        setShowModal={setShowUserActionsModal}
        title="إجراءات"
        content={
          <div className="w-96">
            <CustomButton
              title="عرض بيانات المستخدم"
              customStyle="p-4 mb-5"
              handleClick={() => {
                setShowUserActionsModal(false);
                setShowViewUserModal(true);
              }}
            />
            <CustomButton
              title="تعديل بيانات المستخدم"
              customStyle="p-4 mb-5"
              handleClick={() => {
                setShowUserActionsModal(false);
                setShowEditUserModal(true);
              }}
            />
            <form onSubmit={(e) => handleBlock(e)}>
              <CustomButton
                title={
                  selectedUser.isBanned === true ? "فك الحظر" : "حظر المستخدم"
                }
                type="danger"
                customStyle="p-4 mb-5"
                isLoading={isLoading}
              />
            </form>
          </div>
        }
      />

      {/* Add New User Modal */}
      <CustomModal
        showModal={showAddUserModal}
        setShowModal={setShowAddUserModal}
        title="إضافة مستخدم"
        height="h-156"
        content={<AddUserForm />}
      />

      {/* Show User Info Modal */}
      <CustomModal
        showModal={showViewUserModal}
        setShowModal={setShowViewUserModal}
        title="عرض بيانات المستخدم"
        height="h-156"
        content={<ViewUserInfoForm userInfo={selectedUser} />}
      />

      {/* Show Edit User Info Modal */}
      <CustomModal
        showModal={showEditUserModal}
        setShowModal={setShowEditUserModal}
        title="تعديل بيانات المستخدم"
        height="h-156"
        content={<EditUserForm userInfo={selectedUser} />}
      />
    </div>
  );
}
