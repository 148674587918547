import React from "react";

export default function CampaignStats({ title, value, status }) {
  const statusStyle =
    status === "DELETED"
      ? "danger-color"
      : status === "PREACTIVE"
      ? "text-2-color"
      : status === "ACTIVE"
      ? "blue"
      : status === "COMPLETED"
      ? "success-color"
      : "";
  return (
    <div className={`bg-${statusStyle} bg-opacity-20 rounded-xl`}>
      <div
        className={`bg-${statusStyle} py-14 text-center text-white rounded-xl`}
      >
        <p>
          {status === "DELETED"
            ? "المحذوفة"
            : status === "PREACTIVE"
            ? "المتاحة للحجز"
            : status === "ACTIVE"
            ? "الفرص النشطة"
            : status === "COMPLETED"
            ? "الفرص المغلقة"
            : ""}
        </p>
      </div>
      <div className={`py-28 text-center text-${statusStyle} text-2xl`}>
        <p>{value}</p>
      </div>
    </div>
  );
}
