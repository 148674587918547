import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const uploadImage = async(img, collection, title, name) => {
  const storage = getStorage();
  const imageRef = ref(storage, `${collection}/${title}/${name}`);

  try{
    await uploadBytes(imageRef, img);
    const url = await getDownloadURL(imageRef);
    console.log(url);
    return url;
  }catch(e){
    console.error("Error uploading image: ", e);
    return null;
  }

};

export default uploadImage;
