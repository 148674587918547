import React from 'react';

const ImagePicker = ({ selectedImage, setSelectedImage, imgUrl, setImgUrl, customStyle }) => {
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleDeleteImage = () => {
    setSelectedImage(null);
    setImgUrl('');
  };

  return (
    <label htmlFor="upload" className="cursor-pointer">
      <div className={`${customStyle} relative w-full h-96 border bg-box-color border-2 border-dashed border-gray-300 rounded-2xl flex justify-center items-center`}>
        {selectedImage ? (
          <>
            <img src={URL.createObjectURL(selectedImage)} alt="Preview" className="w-full h-full object-contain rounded-2xl" />
            <button
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600 z-50"
              onClick={handleDeleteImage}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </>
        ) : imgUrl ? (
          <>
            <img src={imgUrl} alt="Preview" className="w-full h-full object-contain rounded-2xl" />
            <button
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600 z-50"
              onClick={handleDeleteImage}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </>
        ) : (
          <label htmlFor="upload" className="cursor-pointer">
            <svg
              className="w-20 h-20 mb-4 text-text-3-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <input id="upload" type="file" className="hidden" onChange={handleImageChange} accept="image/*" />
          </label>
        )}
      </div>
    </label>
  );
};

export default ImagePicker;
