import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const percentagePlugin = {
  id: 'percentagePlugin',
  afterDatasetsDraw: (chart) => {
    const { ctx, data, chartArea: { top } } = chart;
    ctx.font = 'bold 16px Effra';
    ctx.fillStyle = '#001334';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';

    data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      meta.data.forEach((bar, index) => {
        const value = dataset.data[index];
        const total = dataset.data.reduce((sum, current) => sum + current, 0);
        const percentage = ((value / total) * 100).toFixed(2) + '%';
        // Adjust the y-position to ensure the label is visible
        const yPos = Math.max(bar.y - 5, top + 20); // Add top padding
        ctx.fillText(percentage, bar.x, yPos);
      });
    });
  },
};

export default function BarChart({ labels, values }) {
  const data = {
    labels: labels,
    datasets: [
      {
        // label: '',
        data: values,
        backgroundColor: "#ede0be",
        borderColor: "#E7C067",
        borderWidth: 2,
        borderRadius: 5,
        barThickness: 30,
      },
    ],
  };

  const options = {
    percentagePlugin: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          color: '#385766',
          font: {
            family: 'Effra',
            size: 15,
            weight: 'bold',
          },
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  return <Bar data={data} options={options} plugins={[percentagePlugin]} />;
}
