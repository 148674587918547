import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomTextArea from "../../components/CustomTextArea";
import UploadButton from "../../components/UploadButton";
import CustomButton from "../../components/CustomButton";
import uploadImage from "../../lib/firebase_storage/uploadImage";
import createCampaign from "../../lib/campaigns/createCampaign";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import notify from "../../utils/notify";
import createNotification from "../../lib/notifications/createNotification";
import getCampaignsInvestmentsAmount from "../../lib/campaigns/getCampaignsInvestmentsAmount";
import getInvestmentByStatus from "../../lib/investments/getInvestmentByStatus";
import generateId from "../../utils/generateId";

export default function CreateInvestmentOpportunityPage() {
  const [title, setTitle] = useState("");
  const [investmentMaxAmount, setInvestmentMaxAmount] = useState(0);
  // const [investmentROI, setInvestmentROI] = useState(0);
  const [projectStatus, setProjectStatus] = useState("");
  const [shareNumber, setShareNumber] = useState(0);
  const [sharePrice, setSharePrice] = useState(0);
  // const [projectDuration, setProjectDuration] = useState(0);
  // const [avgOccupancyRate, setAvgOccupancyRate] = useState(0);
  // const [expectedPaybackPeriodYears, setExpectedPaybackPeriodYears] =
  //   useState(0);
  const [unitsNumber, setUnitsNumber] = useState(0);
  const [location, setLocation] = useState("");
  const [locationUrl, setLocationUrl] = useState("");
  const [preActiveRemainingTime, setPreActiveRemainingTime] = useState("");
  const [leaseAgreementNumber, setLeaseAgreementNumber] = useState("");
  const [leaseAgreementDate, setLeaseAgreementDate] = useState("");
  const [description, setDescription] = useState("");
  // const [terms, setTerms] = useState("");
  const [currentTotalInvestmentAmount, setCurrentTotalInvestmentAmount] =
    useState(0);
  const [imageFiles, setImageFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvestmentsAmount = async () => {
      const total = await getCampaignsInvestmentsAmount();
      setCurrentTotalInvestmentAmount(total);
    };
    fetchInvestmentsAmount();
  }, []);

  const handleImagesUpload = async () => {
    try {
      const uniqueId = generateId();
      const uploadPromises = imageFiles.map((img) => uploadImage(img, 'campaigns', title, uniqueId));
      const uploadedImages = await Promise.all(uploadPromises);
      return uploadedImages;
    } catch (e) {
      console.error("Error uploading images:", e);
      notify("ERROR", "حصل خطأ.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (imageFiles.length === 0) {
      notify("WARNING", "الرجاء إضافة صور للفرصة");
      setIsLoading(false);
      return;
    }
    try {
      // Upload image to storage
      const images = await handleImagesUpload();
      // Create campaign
      await createCampaign({
        title: title,
        investmentMaxAmount: Number(investmentMaxAmount),
        // investmentROI: Number(investmentROI),
        status: "HIDDEN",
        projectStatus: projectStatus,
        shareNumber: Number(shareNumber),
        sharePrice: Number(sharePrice),
        // projectDuration: Number(projectDuration),
        // avgOccupancyRate: Number(avgOccupancyRate),
        // expectedPaybackPeriodYears: Number(expectedPaybackPeriodYears),
        unitsNumber: Number(unitsNumber),
        locationDescription: location,
        locationUrl: locationUrl,
        preActiveRemainingTime: preActiveRemainingTime,
        leaseAgreementNumber: leaseAgreementNumber,
        leaseAgreementDate: leaseAgreementDate,
        description: description,
        // terms: terms,
        images: images,
        createdAt: Timestamp.now(),
      });

      const approvedInvestments = await getInvestmentByStatus("APPROVED");
      // Create a Set to keep track of unique user IDs
      const uniqueUserIds = new Set();

      approvedInvestments.forEach(async (investment, index) => {
        if (!uniqueUserIds.has(investment.userId)) {
          uniqueUserIds.add(investment.userId);

          await createNotification({
            title: title,
            message: `تم تغيير قيمة المحفظة بعد إضافة عقار ${title} من ${currentTotalInvestmentAmount.toLocaleString()} ريال إلى ${(
              parseInt(currentTotalInvestmentAmount) +
              parseInt(investmentMaxAmount)
            ).toLocaleString()} ريال يرجى التحقق من نسبتكم الجديدة في المحفظة`,
            data: { type: "NEW_CAMPAIGN" },
            toUser: investment.userId,
            isRead: false,
            createdAt: Timestamp.now(),
          });
        }

        if (approvedInvestments.length === index + 1) {
          navigate("/opportunities_management");
        }
      });
      console.log("Done!");
      navigate("/opportunities_management");
    } catch (e) {
      console.error(e);
      notify("ERROR", "حصل خطأ، الرجاء المحاولة لاحقاً.");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="mb-10">
        <PageTitle title="إضافة عقار" />
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="grid grid-cols-2 gap-10 mb-10">
          <div>
            <label className="text-text-2-color font-bold">اسم العقار</label>
            <CustomInput
              type="text"
              value={title}
              setValue={setTitle}
              placeholder="ادخل اسم العقار..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              المبلغ المطلوب للاستثمار (ريال)
            </label>
            <CustomInput
              type="number"
              value={investmentMaxAmount}
              setValue={setInvestmentMaxAmount}
              placeholder="ادخل المبلغ المطلوب للاستثمار..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          {/* <div>
            <label className="text-text-2-color font-bold">
              العائد السنوي (%)
            </label>
            <CustomInput
              type="number"
              value={investmentROI}
              setValue={setInvestmentROI}
              placeholder="ادخل العائد السنوي (نسبة)..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div> */}
          <div>
            <label className="text-text-2-color font-bold">مرحلة المشروع</label>
            <CustomInput
              type="text"
              value={projectStatus}
              setValue={setProjectStatus}
              placeholder="ادخل مرحلة المشروع..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">عدد الحصص</label>
            <CustomInput
              type="number"
              value={shareNumber}
              setValue={setShareNumber}
              placeholder="ادخل عدد الحصص..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              قيمة الحصة الواحدة (ريال)
            </label>
            <CustomInput
              type="number"
              value={sharePrice}
              setValue={setSharePrice}
              placeholder="ادخل قيمة الحصة الواحدة بالريال..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          {/* <div>
            <label className="text-text-2-color font-bold">
              مدة المشروع (شهر)
            </label>
            <CustomInput
              type="number"
              value={projectDuration}
              setValue={setProjectDuration}
              placeholder="ادخل مدة المشروع بالأشهر..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div> */}
          {/* <div>
            <label className="text-text-2-color font-bold">
              متوسط معدل الإشغال (%)
            </label>
            <CustomInput
              type="number"
              value={avgOccupancyRate}
              setValue={setAvgOccupancyRate}
              placeholder="ادخل متوسط معدل الإشغال بالنسبة المئوية..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div> */}
          {/* <div>
            <label className="text-text-2-color font-bold">
              فترة استرداد رأس المال المتوقعة بالسنوات (سنة)
            </label>
            <CustomInput
              type="number"
              value={expectedPaybackPeriodYears}
              setValue={setExpectedPaybackPeriodYears}
              placeholder="ادخل فترة استرداد رأس المال المتوقعة بالسنوات..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div> */}
          <div>
            <label className="text-text-2-color font-bold">عدد الوحدات</label>
            <CustomInput
              type="number"
              value={unitsNumber}
              setValue={setUnitsNumber}
              placeholder="ادخل عدد الوحدات..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              الموقع للمشروع
            </label>
            <CustomInput
              type="text"
              value={location}
              setValue={setLocation}
              placeholder="مثال: الرياض-النرجس..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              رابط موقع المشروع
            </label>
            <CustomInput
              type="text"
              value={locationUrl}
              setValue={setLocationUrl}
              placeholder="ادخل رابط موقع المشروع..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
        </div>
        <div className="mb-10">
          <label className="text-text-2-color font-bold mb-10">
            الوقت المتبقي لإغلاق الحجز
          </label>
          <CustomInput
            type="text"
            value={preActiveRemainingTime}
            setValue={setPreActiveRemainingTime}
            placeholder="مثال: 3 أيام"
            customStyle="mt-5"
            isRequired={true}
          />
        </div>
        <div className="grid grid-cols-2 gap-10 mb-10">
          <div>
            <label className="text-text-2-color font-bold">
              رقم عقد الإيجار
            </label>
            <CustomInput
              type="text"
              value={leaseAgreementNumber}
              setValue={setLeaseAgreementNumber}
              placeholder="ادخل رقم عقد الإيجار..."
              customStyle="mt-5"
              isRequired={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">تاريخ الإيجار</label>
            <CustomInput
              type="date"
              value={leaseAgreementDate}
              setValue={setLeaseAgreementDate}
              placeholder="ادخل تاريخ الإيجار بالميلادي..."
              customStyle="mt-5 text-right"
              isRequired={true}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 mb-10">
          <div>
            <label className="text-text-2-color font-bold mb-10">
              وصف عن العقار
            </label>
            <CustomTextArea
              placeholder="ادخل وصف عن العقار..."
              value={description}
              setValue={setDescription}
              customStyle="mt-5 h-96"
              isRequired={true}
            />
          </div>
          {/* <div>
            <label className="text-text-2-color font-bold">
              شروط وأحكام الفرصة الإستثمارية
            </label>
            <CustomTextArea
              placeholder="ادخل شروط وأحكام الفرصة الإستثمارية..."
              value={terms}
              setValue={setTerms}
              customStyle="mt-5 h-96"
              isRequired={true}
            />
          </div> */}
        </div>

        <label className="text-text-2-color font-bold">صور عن العقار</label>
        <div className="grid grid-cols-2 gap-10 mt-5 mb-16">
          <UploadButton imageFiles={imageFiles} setImageFiles={setImageFiles} />
        </div>
        <CustomButton
          title="نشر العقار"
          customStyle="p-7"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
}
