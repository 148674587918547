import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import CustomLabel from "../../components/CustomLabel";
import { INCOME } from "../../helpers/income";
import { NATIONALITIES } from "../../helpers/nationalities";
import { REGIONS } from "../../helpers/regions";
import getInvestmentByStatus from "../../lib/investments/getInvestmentByStatus";
import getUserById from "../../lib/users/getUserById";
import PageLoader from "../../components/PageLoader";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CONTRACT_TYPES } from "../../helpers/contract_types";
import fileIcon from "../../assets/icons/file.png";
import fileIcon1 from "../../assets/icons/file1.png";
import updateUser from "../../lib/users/updateUser";
import notify from "../../utils/notify";
import uploadImage from "../../lib/firebase_storage/uploadImage";

export default function InvestorsManagementPage() {
  const [investors, setInvestors] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInvestors = async () => {
      let users = [];
      const allInvestments = await getInvestmentByStatus("APPROVED");
      const uniqueUserIds = new Set();
      for (const investment of allInvestments) {
        if (!uniqueUserIds.has(investment.userId)) {
          uniqueUserIds.add(investment.userId);
          const userId = investment.userId;
        const user = await getUserById(userId);
        users.push(user);
        }
        
      }
      setInvestors(users);
      setIsPageLoading(false);
    };
    fetchInvestors();
  }, []);
  const tableHeaders = [
    "اسم المستخدم",
    "رقم الجوال",
    "المدينة",
    "الجنسية",
    "الدخل الشهري",
    "حساب بنكي",
    "الحالة",
    "نوع العقد",
    "إجراء",
  ];

  const handleContractToFILE = async () => {
    if (contractFile === null) {
      notify("ERROR", "لم يتم إرفاق العقد");
      return;
    }
    setIsLoading(true);
    try {
      const contractUrl = await uploadImage(
        contractFile,
        "users",
        selectedInvestor.id,
        `CONTRACT_${selectedInvestor.id}`
      );
      await updateUser(selectedInvestor.id, {
        contractFileURL: contractUrl,
        contractType: "FILE",
      });
      navigate(0);
    } catch (error) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ.");
    }
  };

  const handleContractToAUTOMATED = async () => {
    setIsLoading(true);
    try {
      await updateUser(selectedInvestor.id, { contractType: "AUTOMATED" });
      navigate(0);
    } catch (error) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ.");
    }
  };
  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <PageTitle title="إدارة المستثمرين" />
      <div className="flex flex-row gap-4 my-10 w-72">
        <CustomButton
          title="التقارير"
          customStyle="p-3"
          handleClick={() => navigate("/general_reports")}
        />
        <CustomButton
          title="الإشعارات"
          customStyle="p-3"
          handleClick={() => navigate("/notifications_management")}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {investors.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.name}</td>
                      <td className="px-6 py-4" dir="ltr">
                        {column.phone}
                      </td>
                      <td className="px-6 py-4">
                        {REGIONS["SA"][column.city]}
                      </td>
                      <td className="px-6 py-4">
                        {NATIONALITIES[column.nationality]}
                      </td>
                      <td className="px-6 py-4">{INCOME[column.income]}</td>
                      {column.bankAccounts.length > 0 ? (
                        <td className="px-6 py-4">يوجد</td>
                      ) : (
                        <td className="px-6 py-4">لا يوجد</td>
                      )}
                      {column.isBanned === true ? (
                        <td className="px-6 py-4">محظور</td>
                      ) : (
                        <td className="px-6 py-4">متاح</td>
                      )}
                      <td className="px-6 py-4">
                        <CustomLabel
                          title={CONTRACT_TYPES[column.contractType] ?? CONTRACT_TYPES["AUTOMATED"]}
                          status={
                            column.contractType !== "FILE"
                              ? "BLUE"
                              : "PURPLE"
                          }
                        />
                      </td>
                      <td className="px-6 py-4">
                        <CustomButton
                          title="إجراء"
                          customStyle="p-2"
                          handleClick={() => {
                            setContractFile(null);
                            setSelectedInvestor(column);
                            setShowContractModal(true);
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <CustomModal
        showModal={showContractModal}
        setShowModal={setShowContractModal}
        title={""}
        height="h-156"
        customStyle="h-full w-128"
        content={
          <>
            <div className="flex items-center gap-5 mb-10">
              <p className="text-text-1-color">حالة العقد الآن</p>
              <CustomLabel
                title={CONTRACT_TYPES[selectedInvestor.contractType] ?? CONTRACT_TYPES["AUTOMATED"]}
                status={
                  selectedInvestor.contractType !== "FILE"
                    ? 'BLUE'
                    : 'PURPLE'
                }
              />
            </div>
            <div className="bg-ice-color p-5 rounded-full flex justify-between items-center mb-10">
              <div>
                <CustomButton
                  buttonType="button"
                  title="تحميل العقد"
                  customStyle="py-2 px-10"
                  handleClick={() =>{
                    if(selectedInvestor.contractType === 'FILE'){
                      window.open(selectedInvestor.contractFileURL, "_blank");
                    }else{
                      window.open(`/automated_contract/${selectedInvestor.id}`, "_blank");
                    }
                  }
                  }
                />
              </div>
              <img
                src={fileIcon}
                alt="file"
                className="h-12 w-12 m-auto mobile:hidden laptop:flex"
              />
            </div>
            <div className="bg-ice-color py-10 rounded-xl mb-10 text-center">
              <p className="text-sm text-text-2-color">
                {selectedInvestor.contractType !== "FILE"
                  ? "إذا كنت تريد تحويل العقد إلى عقد ورقي يتطلب رفع عقد بصيغة PDF"
                  : "يمكنك تحويل العقد إلى عقد إلكتروني بشكل مباشر عن طريق الضغط على الزر وبدون رفع أي ملفات"}
              </p>
            </div>
            {selectedInvestor.contractType !== "FILE" ? (
              <div className="border-2 border-dashed bg-box-color px-10 py-24 rounded-xl mb-10 relative cursor-pointer">
                {contractFile == null ? (
                  <>
                    <input
                      type="file"
                      className="absolute inset-0 opacity-0 cursor-pointer"
                      onChange={(e) => {
                        setContractFile(e.target.files[0]);
                      }}
                    />
                    <img
                      src={fileIcon1}
                      alt="file"
                      className="h-12 w-12 m-auto mobile:hidden laptop:flex"
                    />{" "}
                  </>
                ) : (
                  <div className="text-center">
                    <p>{contractFile.name}</p>
                    <CustomButton
                      title="حذف الملف"
                      type="danger"
                      customStyle="p-3"
                      handleClick={() => setContractFile(null)}
                    />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            <CustomButton
              isLoading={isLoading}
              title={
                selectedInvestor.contractType !== "FILE"
                  ? "تم رفع ملف العقد الورقي وأريد تحويل العقد إلى ورقي!"
                  : "نعم أريد تحويل العقد إلى عقد إلكتروني"
              }
              customStyle="p-4 text-sm"
              handleClick={
                selectedInvestor.contractType !== "FILE"
                  ? handleContractToFILE
                  : handleContractToAUTOMATED
              }
            />
          </>
        }
      />
      
    </div>
  );
}
