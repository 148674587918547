import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestmentsByUserId = async (uid) => {
  const investmentsCollection = collection(firestore, "investments");
  const q = query(
    investmentsCollection,
    where("status", "==", "APPROVED"),
    where("userId", "==", uid),
    orderBy("createdAt", "desc")
  );
  const querySnapshot = await getDocs(q);

  const investmentsData = querySnapshot.docs.map((data) => {
    var invObj = data.data();
    invObj["id"] = data.id;
    return invObj;
  });

  return investmentsData;
};

export default getInvestmentsByUserId;
