import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import PageLoader from "../../components/PageLoader";
import { ToastContainer } from "react-toastify";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import notify from "../../utils/notify";
import AddInvestmentPromoForm from "./component/AddInvestmentPromoForm";
import EditInvestmentPromoForm from "./component/EditInvestmentPromoForm";
import getInvestmentPromos from "../../lib/investment_promos/getInvestmentPromos";
import updateInvestmentPromo from "../../lib/investment_promos/updateInvestmentPromo";

export default function InvestmentPromosManagement() {
  useEffect(() => {
    const fetchRegistrationPromos = async () => {
      const fetchedPromos = await getInvestmentPromos();
      setInvestmentPromos(fetchedPromos);
      setIsPageLoading(false);
    };
    fetchRegistrationPromos();
  }, []);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [investmentPromos, setInvestmentPromos] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState({});
  const [showAddPromoCodeModal, setShowAddPromoCodeModal] = useState(false);
  const [showEditPromoCodeModal, setShowEditPromoCodeModal] = useState(false);
  const [showConfirmationModal, setShowConfirmaionModal] = useState(false);
  const tableHeaders = ["الكود", "المالك", "رقم الجوال", "إجراءات"];

  const handleDelete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
        await updateInvestmentPromo(selectedPromo.id, { isActive: false });
      navigate(0);
    } catch (e) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ.");
    }
  };

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="flex flex-row justify-between items-center mb-16">
        <PageTitle title="إدارة الأكواد الترويجية الإستثمارية" />
        <div className="w-60">
          <CustomButton
            title="إنشاء كود جديد"
            customStyle="p-2 mb-5"
            handleClick={() => setShowAddPromoCodeModal(true)}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {investmentPromos.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.code}</td>
                      <td className="px-6 py-4">{column.owner}</td>
                      <td className="px-6 py-4" dir="ltr">
                        {column.phone}
                      </td>
                      <td className="px-6 py-4 flex flex-row ">
                        <CustomButton
                          title="عرض السجل"
                          customStyle="p-2 ml-2"
                          handleClick={() =>
                            navigate(`/investment_promos/${column.id}`)
                          }
                        />
                        <CustomButton
                          title="تعديل البيانات"
                          customStyle="p-2 ml-2"
                          handleClick={() => {
                            setSelectedPromo(column);
                            setShowEditPromoCodeModal(true);
                          }}
                        />
                        <CustomButton
                          title="تعطيل"
                          customStyle="p-2"
                          type="danger"
                          handleClick={() => {
                            setSelectedPromo(column);
                            setShowConfirmaionModal(true);
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Create new promo code modal */}
      <CustomModal
        showModal={showAddPromoCodeModal}
        setShowModal={setShowAddPromoCodeModal}
        title="إنشاء كود جديد"
        height="h-132"
        content={<AddInvestmentPromoForm />}
      />

      {/* Edit promo code modal */}
      <CustomModal
        showModal={showEditPromoCodeModal}
        setShowModal={setShowEditPromoCodeModal}
        title="تعديل الكود"
        height="h-132"
        content={<EditInvestmentPromoForm promoInfo={selectedPromo} />}
      />

      {/* Confirm code deactivation */}
      <CustomModal
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmaionModal}
        title="حذف الكود"
        content={
          <div className="w-96 text-center">
            <p className="text-text-2-color mb-5 text-lg">
              هل أنت متأكد من تعطيل الكود؟ لن يتم عرض الكود مجددا في إدارة الأكواد
              الترويجية الإستثمارية.
            </p>
            <div className="grid grid-cols-2 gap-10">
              <CustomButton
                title="تعطيل"
                customStyle="py-5"
                type="danger"
                isLoading={isLoading}
                handleClick={handleDelete}
              />
              <CustomButton
                title="الغاء"
                customStyle="py-5"
                isLoading={isLoading}
                handleClick={() => setShowConfirmaionModal(false)}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
