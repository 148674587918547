import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getRegistrationPromosUsagesByUser = async (id) => {
    const regPromosUsageCollection = collection(firestore, "registrationPromosUsage");
    const q = query(regPromosUsageCollection, where("userId", "==", id));
    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
    
}
export default getRegistrationPromosUsagesByUser;