import React, { useEffect, useState } from "react";
import PageLoader from "../../components/PageLoader";
import { ToastContainer } from "react-toastify";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import getAnnouncements from "../../lib/announcements/getAnnouncements";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import getVotingCount from "../../lib/announcements/getVotingCount";
import { RotatingLines } from "react-loader-spinner";

export default function AnnouncementsPage() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [showVotingModal, setShowVotingModal] = useState(false);
  const [votingCounts, setVotingCounts] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const announcements = await getAnnouncements();
      setAnnouncements(announcements);
      setIsPageLoading(false);
    };

    fetchAnnouncements();
  }, []);

  useEffect(() => {
    const fetchVotingCounts = async () => {
      setIsLoading(true);
      const counts = {};
      for (const ann of announcements) {
        for (const key of Object.keys(ann.votingOptions)) {
          const count = await getVotingCount(key);
          counts[key] = count;
        }
      }
      setVotingCounts(counts);
      setIsLoading(false);
    };

    if (showVotingModal) {
      fetchVotingCounts();
    }
  }, [showVotingModal, announcements]);

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <div>
        <div className="flex flex-row justify-start items-center mb-10">
          <PageTitle title={"نظام التصويت"} isSubtitle={true} />
        </div>

        <div className="grid mobile:grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4">
          {announcements.map((ann, index) => {
            return (
              <div
                key={index}
                className="bg-box-color rounded-3xl flex flex-col items-center text-center py-10"
              >
                <div className="px-10 mb-5">
                  <img
                    src={ann.image}
                    alt="title"
                    className="w-full h-full object-contain rounded-3xl"
                  />
                </div>

                <div className="px-10">
                  <p className="text-text-1-color font-bold mb-5">
                    {ann.title}
                  </p>
                  <p className="text-text-2-color text-sm">{ann.message}</p>
                </div>
                <div className="w-full h-px bg-ice2-color my-5"></div>
                <div className="mb-5">
                  {Object.entries(ann.votingOptions)
                    .sort((a, b) => a[1].localeCompare(b[1]))
                    .map(([key, value]) => {
                      return (
                        <p className="text-primary-color mb-5" key={key}>
                          {value}
                        </p>
                      );
                    })}
                </div>

                <div>
                  <CustomButton
                    title="عرض نتائج التصويت"
                    customStyle="py-3 px-5"
                    handleClick={() => {
                      setShowVotingModal(true);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <CustomModal
        showModal={showVotingModal}
        setShowModal={setShowVotingModal}
        title="نتائج التصويت"
        height="h-156 m-auto"
        customStyle={`${isLoading ? "overflow-hidden" : null} flex justify-center`}
        content={
          isLoading ? (
            <RotatingLines
              strokeColor="#E7C067"
              strokeWidth="5"
              animationDuration="0.50"
              width="50"
              visible={true}
            />
          ) : (
            <div className="flex flex-col gap-10">
              {announcements.map((ann, index) => {
                return Object.entries(ann.votingOptions)
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([key, value]) => {
                    return (
                      <div
                        key={`${key}_${index}`}
                        className="bg-box-color p-10 rounded-3xl flex flex-col gap-5 w-96 items-center"
                      >
                        <p className="text-text-2-color">{value}</p>
                        <p className="text-primary-color">
                          {votingCounts[key]}
                        </p>
                      </div>
                    );
                  });
              })}
            </div>
          )
        }
      />
    </div>
  );
}
