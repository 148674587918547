import React from "react";

export default function StatsItem({ title1, title2, title3 }) {
  return (
    <div className="p-5 bg-ice-color text-center flex flex-col rounded-xl">
      <p className="text-text-2-color mb-5">{title1}</p>
      <p className="text-text-1-color mb-5">{title2}</p>
      <p className="text-primary-color">{title3}</p>
    </div>
  );
}
