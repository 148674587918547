import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestmentByStatus = async (status) => {
    const investmentsCollection = collection(firestore, "investments");
    const q = query(investmentsCollection, where("status", "==", status));
    const querySnapshot = await getDocs(q);

    const investmentsData = querySnapshot.docs.map((data) => {
        var invObj = data.data();
        invObj['id'] = data.id;
        return invObj
    });
    
    return investmentsData;
}
export default getInvestmentByStatus;