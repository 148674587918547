import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const notify = (type, message) => {

  if(type === "SUCCESS"){
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
  }else if(type === "ERROR"){
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
  }else if(type === "WARNING"){
    toast.warn(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
  }else if(type === "INFO"){
    toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false
      });
  }
};

export default notify;