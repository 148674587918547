import React, { useState } from "react";
import fileIcon from "../assets/icons/file.png"; // Import your file icon image
import CustomButton from "./CustomButton";
import CustomModal from "./CustomModal";

const FilePicker = ({
  selectedFile,
  setSelectedFile,
  fileUrl,
  setFileUrl,
  modalTitle,
}) => {

  // Function to handle file selection
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
    setFileUrl('');
  };

  const [showFileModal, setShowFileModal] = useState(false);

  return (
    <>
      {selectedFile || fileUrl ? (
        <div className="bg-ice2-color p-10 rounded-full flex justify-between">
          <div className="flex gap-10 mobile:w-full laptop:w-1/3">
            <CustomButton
              buttonType="button"
              handleClick={() => {
                if(fileUrl){
                  window.open(fileUrl, '_blank', 'noopener,noreferrer');
                }else{
                  setShowFileModal(true);
                }
              }}
              title="عرض الوثيقة"
              customStyle="p-4"
            />
            <CustomButton
              type="danger"
              buttonType="button"
              handleClick={handleDeleteFile}
              title="حذف الوثيقة"
              customStyle="p-4"
            />
          </div>

          <img src={fileIcon} alt="File Icon" className="h-16 w-16 m-auto mobile:hidden laptop:flex" />
        </div>
      ) : (
        <div className="flex justify-between">
          <label
            className={`relative w-full h-96 border bg-box-color border-2 border-dashed border-gray-300 rounded-2xl flex justify-center items-center`}
          >
            <label htmlFor="upload" className="cursor-pointer">
              <svg
                className="w-20 h-20 mb-4 text-text-3-color"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <input
                id="upload"
                type="file"
                className="hidden"
                onChange={handleFileSelect}
                accept=".pdf"
              />
            </label>
            <input type="file" className="hidden" onChange={handleFileSelect} />
          </label>
        </div>
      )}

      <CustomModal
        showModal={showFileModal}
        setShowModal={setShowFileModal}
        title={modalTitle}
        height="h-156"
        customStyle="h-full w-full"
        content={
          selectedFile ? (
            <embed
              src={URL.createObjectURL(selectedFile)}
              width="100%"
              height="100%"
            />
          ) : (
            fileUrl && <embed src={fileUrl} width="100%" height="100%" />
          )
        }
      />
    </>
  );
};

export default FilePicker;
